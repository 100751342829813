<template>
  <svg class="loading-circle" viewBox="0 0 100 100">
    <circle class="loading-circle__bg" cx="50" cy="50" r="45" />
    <circle class="loading-circle__progress" cx="50" cy="50" r="45" />
  </svg>
</template>

<style lang="scss" scoped>
.loading-circle {
  --loading-duration: 3s;
  --animation-delay: 1s;
  --stroke-width: 4px;
  transform: rotate(-90deg);

  &__bg {
    fill: none;
    stroke-width: var(--stroke-width);
    stroke: $c-white;
    opacity: 0.2;
  }

  &__progress {
    fill: none;
    stroke: $c-white;
    stroke-width: var(--stroke-width);
    stroke-linecap: round;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;

    animation: fillCircle var(--loading-duration) var(--animation-delay) ease
      forwards;
  }
}

@keyframes fillCircle {
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
