import {
  Book,
  Doc,
  DocListItem,
  Annotation,
  AnnotationContent,
  Billing,
  Comment,
  Exercise,
  Plan,
  Thread,
  ThreadForDoc,
  ExcerptData,
  CustomList,
  CustomListListItem,
} from '@/models/interfaces'
import { QuestionnaireUserChoices } from '@/models/long.onboarding.types'
import { ContentView, DocSortOption, DocView } from '@/models/doc'
import { platform } from '@/services/platform'
import { Sentry } from '@/services/sentry'
import { offline } from '@/services/offline'
import { router } from '@/helpers/router'

import {
  hasMixpanel,
  pageView as basePageView,
  _track as baseTrack,
  _trackAsync as baseTrackAsync,
} from './base'

export function forgotPassword(): void {
  _track('Forgot Password Request')
}

export function resetPassword(): void {
  _track('Forgot Password Reset')
}

export async function pageView(): Promise<void> {
  try {
    await _setSuperProperties()
  } catch (error) {
    Sentry.captureException(error)
  }
  basePageView()
}

export function trackBookmarkItemTOCClick(bookmark: Annotation): void {
  _track(
    'Click Bookmark Item List',
    {
      bookmark: bookmark.id,
    },
    {
      'Last Click Bookmark Item List': new Date().toISOString(),
    },
    {
      'Click Bookmark Item List Count': 1,
    },
  )
}

export function trackSidebarItemClick(item: string): void {
  _track(
    'Click Reading Sidebar Item',
    {
      item: item,
    },
    {
      [`Last Click Reading Sidebar ${item} Click`]: new Date().toISOString(),
    },
    {
      [`Click Reading Sidebar ${item} Click Count`]: 1,
    },
  )
}
export function trackCopyButtonClickReadingPage(): void {
  _track('Copy Content Button Click')

  _track(
    'Copy Content Button Click',
    {
      'Last Copy Content Button Click': new Date().toISOString(),
    },
    {
      'Copy Content Button Clicked': 1,
    },
  )
}

export function trackBilling(
  new_plan: Plan,
  billing: Billing,
  done: () => void,
): void {
  _track(
    'Billing Change',
    {
      from: billing.plan_id,
      to: new_plan.id,
      to_amount: new_plan.amount,
      to_nickname: new_plan.nickname,
      from_current_period_end: billing.current_period_end_datetime,
      from_is_trial: billing.is_trial,
      from_is_limited: billing.is_limited,
      from_trial_start: billing.trial_start_datetime,
      from_trial_end: billing.trial_end_datetime,
    },
    {
      'Last Billing Change': new Date().toISOString(),
    },
    {
      'Billing Changes': 1,
    },
    done,
  )
}

export function trackCardSave(billing: Billing): void {
  try {
    _track(
      'Card Save',
      {
        billing_type: billing.billing_type,
      },
      {
        'Last Card Save': new Date().toISOString(),
      },
      {
        'Card Saved': 1,
      },
    )
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function trackCancelTrial(billing: Billing): void {
  _track('Trial Cancel', { billing_type: billing.billing_type })
}

export function trackStartTrial(plan: Plan, location: string): void {
  _track('Start Trial Button Clicked', { plan_id: plan.id, location })
}

export function trackMoveBookToList(doc: DocListItem, list: string): void {
  _track(
    'Doc Move To List',
    {
      doc_type: doc.doc_type,
      doc_id: doc.id,
      doc_title: doc.title,
      doc_is_free: doc.is_free,
      list: list,
    },
    {
      'Doc Move To List Last': new Date().toISOString(),
    },
    {
      'Doc Move to List Count': 1,
    },
  )
}

export function trackFavorite(doc: DocListItem, is_favorite: boolean): void {
  const eventName = is_favorite ? 'Favorite' : 'Unfavorite'
  _track(
    `Doc ${eventName}`,
    {
      doc_type: doc.doc_type,
      doc_id: doc.id,
      doc_title: doc.title,
      doc_is_free: doc.is_free,
      is_favorite: is_favorite,
    },
    {
      [`Doc ${eventName} Last`]: new Date().toISOString(),
    },
    {
      [`Doc ${eventName} Count`]: 1,
    },
  )
}

export function trackWantToRead(doc: DocListItem, added: boolean): void {
  const eventName = added ? 'Add to Want to Read' : 'Remove from Want to Read'

  _track(
    `Doc ${eventName}`,
    {
      doc_type: doc.doc_type,
      doc_id: doc.id,
      doc_title: doc.title,
      doc_is_free: doc.is_free,
      custom_lists: doc.custom_lists.map((list) => list.title),
    },
    {
      [`Doc ${eventName} Last`]: new Date().toISOString(),
    },
    {
      [`Doc ${eventName} Count`]: 1,
    },
  )
}

export function trackAddToCustomList(doc: DocListItem): void {
  _track(
    'Doc Add to Custom List',
    {
      doc_type: doc.doc_type,
      doc_id: doc.id,
      doc_title: doc.title,
      doc_is_free: doc.is_free,
      custom_lists: doc.custom_lists.map((list) => list.title),
    },
    {
      ['Doc Add to Custom List Last']: new Date().toISOString(),
    },
    {
      ['Doc Add to Custom List Count']: 1,
    },
  )
}

export function trackCreateCustomList(list: CustomList): void {
  _track(
    'Create Custom List',
    {
      id: list.id,
      title: list.title,
    },
    {
      ['Create Custom List Last']: new Date().toISOString(),
    },
    {
      ['Create Custom List Count']: 1,
    },
  )
}

export function trackDeleteCustomList(list: CustomListListItem): void {
  _track(
    'Delete Custom List',
    {
      id: list.id,
      title: list.title,
    },
    {
      ['Delete Custom List Last']: new Date().toISOString(),
    },
    {
      ['Delete Custom List Count']: 1,
    },
  )
}

export function trackRenameCustomList(
  list: CustomList,
  oldTitle: string,
): void {
  _track(
    'Rename Custom List',
    {
      id: list.id,
      title: list.title,
      old_title: oldTitle,
    },
    {
      ['Rename Custom List Last']: new Date().toISOString(),
    },
    {
      ['Rename Custom List Count']: 1,
    },
  )
}

export function trackExerciseSave(
  content: ContentView,
  exercise: Exercise,
): void {
  if (exercise.submitted_at) {
    _track(
      'Exercise Submit',
      {
        book_id: content.doc.id,
        book_title: content.doc.title,
        book_is_free: content.doc.is_free,
        book_forum_id: content.doc.forum_id,
        exercise_id: exercise.id,
        exercise_submitted_at: exercise.submitted_at,
        content: exercise.content_id,
        content_id: content.id,
        content_book_id: content.doc_id,
        content_title: content.title,
        content_type: content.content_type,
        content_order: content.order,
      },
      {
        'Last Exercise Submit': new Date().toISOString(),
      },
      {
        'Exercises Submitted': 1,
      },
    )
  }
}

export function trackExerciseDiscuss(
  content: ContentView,
  exercise: Exercise,
): void {
  _track(
    'Exercise Discuss',
    {
      book_id: content.doc.id,
      book_title: content.doc.title,
      book_is_free: content.doc.is_free,
      book_forum_id: content.doc.forum_id,
      exercise_id: exercise.id,
      exercise_submitted_at: exercise.submitted_at,
      content: exercise.content_id,
      content_id: content.id,
      content_book_id: content.doc_id,
      content_title: content.title,
      content_type: content.content_type,
      content_order: content.order,
    },
    {
      'Last Exercise Discuss': new Date().toISOString(),
    },
    {
      'Exercise Discussed': 1,
    },
  )
}

export function trackThreadCreate(book: Book, thread: Thread): void {
  _track(
    'Thread Create',
    {
      book_id: book.id,
      book_title: book.title,
      book_is_free: book.is_free,
      book_forum_id: book.forum_id,
      thread_title: thread.title,
    },
    {
      'Last Thread Create': new Date().toISOString(),
    },
    {
      'Thread Created': 1,
    },
  )
}

export function trackThreadUpdate(book: Book, thread: Thread): void {
  _track(
    'Thread Update',
    {
      book_id: book.id,
      book_title: book.title,
      book_is_free: book.is_free,
      book_forum_id: book.forum_id,
      thread_title: thread.title,
      thread_created: thread.created,
      thread_comments_count: thread.comments_count,
    },
    {
      'Last Thread Update': new Date().toISOString(),
    },
    {
      'Thread Updated': 1,
    },
  )
}

export function trackCommentCreate(
  doc: Doc,
  thread: ThreadForDoc,
  comment: Comment,
): void {
  let eventName = 'Comment Create'
  const eventData: any = {
    thread_id: thread.id,
    thread_title: thread.title,
    thread_created: thread.created,
    thread_updated: thread.updated,
    thread_comments_count: thread.comments_count,
    comment_id: comment.id,
    comment_author_id: comment.author.id,
    comment_author_username: comment.author.username,
  }
  if (thread.author) {
    eventData['thread_author_id'] = thread.author.id
    eventData['thread_author_username'] = thread.author.username
  }
  if (doc.doc_type === 'book') {
    eventData['book_id'] = doc.id
    eventData['book_title'] = doc.title
    eventData['book_is_free'] = doc.is_free
    eventData['book_forum_id'] = doc.forum_id
  } else {
    eventName = 'Comment Create Article'
    eventData['article_id'] = doc.id
    eventData['article_title'] = doc.title
    eventData['article_is_free'] = doc.is_free
  }
  _track(
    eventName,
    eventData,
    {
      'Last Comment Create': new Date().toISOString(),
    },
    {
      'Comment Created': 1,
    },
  )
}

export function trackReplyCreate(
  doc: Doc,
  thread: ThreadForDoc,
  comment: Comment,
  reply: Comment,
): void {
  const eventData: any = {
    thread_id: thread.id,
    thread_title: thread.title,
    thread_created: thread.created,
    thread_updated: thread.updated,
    thread_comments_count: thread.comments_count,
    comment_id: comment.id,
    comment_created: comment.created,
    comment_updated: comment.updated,
    comment_author_id: comment.author.id,
    comment_author_username: comment.author.username,
    reply_id: reply.id,
    reply_author_id: reply.author.id,
    reply_author_username: reply.author.username,
  }
  let eventName = 'Reply Create'
  if (thread.author) {
    eventData['thread_author_id'] = thread.author.id
    eventData['thread_author_username'] = thread.author.username
  }
  if (doc.doc_type === 'book') {
    eventData['book_id'] = doc.id
    eventData['book_title'] = doc.title
    eventData['book_is_free'] = doc.is_free
    eventData['book_forum_id'] = doc.forum_id
  } else {
    eventName = 'Reply Create Article'
    eventData['article_id'] = doc.id
    eventData['article_title'] = doc.title
    eventData['article_is_free'] = doc.is_free
  }
  _track(
    eventName,
    eventData,
    {
      'Last Reply Create': new Date().toISOString(),
    },
    {
      'Reply Created': 1,
    },
  )
}

export function trackHighlightCreate(
  content: AnnotationContent,
  annotation: Annotation,
): void {
  _track(
    'Highlight Create',
    {
      book_id: content.doc.id,
      content_id: content.id,
      content_title: content.title,
      highlight_start: annotation.ranges[0].startOffset,
      highlight_end: annotation.ranges[0].endOffset,
      highlight_has_note: annotation.text ? true : false,
      highlight_type: annotation.highlight_type,
    },
    {
      'Last Highlight Create': new Date().toISOString(),
    },
    {
      'Highlight Created': 1,
    },
  )
}

export function trackHighlightShare(
  content: ContentView,
  annotation: Annotation,
): void {
  _track(
    'Highlight Share',
    {
      book_id: content.doc_id,
      content_id: content.id,
      content_title: content.title,
      highlight_start: annotation.ranges[0].startOffset,
      highlight_end: annotation.ranges[0].endOffset,
      highlight_has_note: annotation.text ? true : false,
    },
    {
      'Last Highlight Share': new Date().toISOString(),
    },
    {
      'Highlight Shared': 1,
    },
  )
}

export function trackHighlightUpdate(
  content: AnnotationContent,
  annotation: Annotation,
): void {
  _track(
    'Highlight Update',
    {
      book_id: content.doc.id,
      content_id: content.id,
      content_title: content.title,
      highlight_id: annotation.id,
      highlight_start: annotation.ranges[0].startOffset,
      highlight_end: annotation.ranges[0].endOffset,
      highlight_has_note: annotation.text ? true : false,
      highlight_type: annotation.highlight_type,
    },
    {
      'Last Highlight Update': new Date().toISOString(),
    },
    {
      'Highlight Updated': 1,
    },
  )
}

export function trackHighlightDelete(
  content: AnnotationContent,
  annotation: Annotation,
): void {
  _track(
    'Highlight Delete',
    {
      book_id: content.doc.id,
      content_id: content.id,
      content_title: content.title,
      highlight_id: annotation.id,
      highlight_start: annotation.ranges[0].startOffset,
      highlight_end: annotation.ranges[0].endOffset,
      highlight_has_note: annotation.text ? true : false,
      highlight_type: annotation.highlight_type,
    },
    {
      'Last Highlight Delete': new Date().toISOString(),
    },
    {
      'Highlight Deleted': 1,
    },
  )
}

export function trackBookTagClick(tag: string): void {
  _track(
    'Select Book Category',
    {
      Category: tag,
    },
    {
      'Last Book Category Select': new Date().toISOString(),
    },
    {
      'Book Category Selected': 1,
    },
  )
}

export function trackSort(sortOption: DocSortOption): void {
  _track(
    'Sort Documents',
    {
      'Sort Option': DocSortOption[sortOption],
    },
    {
      'Last Sort': new Date().toISOString(),
    },
    {
      'Sort Used': 1,
    },
  )
}

/**
 * Track the Audio Listen event.
 *
 * Tracks Audio Listen event and updates 'Audio Listen Time' time profile
 * property.
 *
 * Args:
 *    book: the book that is listened to.
 *    timeDiff: number of seconds listened to.
 *    isDownload: if the audio is played back from a native storage download
 */
export function trackAudioListen(
  book: DocView,
  timeDiff: number,
  isDownload: boolean,
): void {
  const timeDiffInt = Math.round(timeDiff)
  if (timeDiffInt === 0) {
    return
  }
  _track(
    'Audio Listen',
    {
      // Event data
      audio_time_diff: timeDiffInt,
      book_id: book.id,
      book_title: book.title,
      book_is_free: book.is_free,
      is_download: isDownload,
      is_offline: offline.isOffline,
    },
    {
      // People set data
      'Last Audio Listen': new Date().toISOString(),
    },
    {
      // People incremental data
      'Audio Listen Time': timeDiffInt,
    },
  )
}

const readTimeEvents = new Map([
  ['book', 'Book'],
  ['article', 'Article'],
  ['podcast_episode', 'PodcastEpisode'],
])

/**
 * Track the Read Time event.
 *
 * Tracks the event and updates '(Article | Book | Podcast Episode) Read Time' time profile
 * property.
 *
 * Args:
 *    doc: the doc that is read.
 *    timeDiff: number of seconds the book was read (open).
 */
export async function trackDocReadTime(
  doc: DocView | null,
  timeDiff: number,
): Promise<void> {
  const timeDiffInt = Math.round(timeDiff)
  if (timeDiffInt === 0) {
    return
  }
  // For tests: we may have book as null for some reason.
  if (!doc) {
    return
  }

  const docName = readTimeEvents.get(doc.doc_type)

  return await _trackAsync(
    `${docName} Read Time`,
    {
      // Event data
      time_diff: timeDiffInt,
      [`${doc.doc_type}_id`]: doc.id,
      [`${doc.doc_type}_title`]: doc.title,
      [`${doc.doc_type}_is_free`]: doc.is_free,
    },
    {
      // People set data
      [`Last ${docName} Read`]: new Date().toISOString(),
    },
    {
      // People incremental data
      [`Read Time ${docName}s`]: timeDiffInt,
    },
  )
}

export function trackAppReviewShow(): void {
  _track('App Review Show')
}

export function trackAppReviewSuccess(): void {
  _track('App Review Success')
}

export function trackAppReviewFailure(): void {
  _track('App Review Failure')
}

/**
 * Track Done button click when finishing the book.
 *
 * Args:
 *    book: the book that is listened to.
 */
export function trackBookDoneClick(book: DocView): void {
  _track(
    'Book Done Click',
    {
      // Event data
      book_id: book.id,
      book_title: book.title,
      book_is_free: book.is_free,
    },
    {
      // People set data
      'Last Book Done Click': new Date().toISOString(),
    },
    {
      // People incremental data
      'Book Done Clicks': 1,
    },
  )
}

export function trackQuestionnaireCategory(categories: string[]): void {
  _track(
    'Select Questionnaire Categories',
    {
      Categories: categories,
    },
    {
      'Last Questionnaire Category Selected': new Date().toISOString(),
    },
    {
      'Questionnarire Category Selected': 1,
    },
  )
}

/**
 * Track that the button to download a book to native device storage was clicked.
 */
export function trackDeviceStorageDownload(book: Book): void {
  _track('Device Storage Download', {
    book_id: book.id,
    book_title: book.title,
  })
}

/**
 * Track that the button to delete a book from native device storage was clicked.
 */
export function trackDeviceStorageDelete(book: Book): void {
  _track('Device Storage Delete', {
    book_id: book.id,
    book_title: book.title,
  })
}

/**
 * Track the outcome of the "ask for notification permission" popup.
 * This event is sent on every app load for 'granted' and only once
 * on 'denied'.
 */
export function trackNotificationPermission(granted: boolean): void {
  _track('Push Notification Permission', { granted })
}

export function trackAccountDeleteClick(): void {
  _track('Account Delete Click')
}

export function trackAccountDeleteCancel(): void {
  _track('Account Delete Cancel')
}

export function trackAccountDeleteRequest(): void {
  _track('Account Delete Request')
}

export async function trackAccountDeleteConfim(): Promise<void> {
  _trackAsync('Account Delete Confirm')
}

/**
 * Track that an excerpt was clicked.
 */
export function trackExcerptClick(excerptData: ExcerptData): void {
  _track('Excerpt Click', {
    excerpt_id: excerptData.id,
    doc_url_slug: excerptData.doc_url_slug,
    content_url_slug: excerptData.content_url_slug,
  })
}

/**
 * Track document view event.
 *
 * To track the document view, we use the following logic:
 *   - From wrapping component, like ``ListingBlock``, we track the click event that
 *     happened within the block
 *   - Using the event, we are trying to find the card element
 *   - If the card element is found, we track doc view event to Mixpanel.
 *
 * @param event: event from the component wrapping the doc card.
 * @param referrerBlockTitle: title of the referrer block or listing page.
 * @param referrerUrl: URL of the referrer page
 */
export function trackDocView(
  event: Event,
  referrerBlockTitle: string,
  referrerUrl: string,
): void {
  function findParentATag(element: HTMLElement): HTMLElement | undefined {
    if (element.tagName === 'A') {
      return element
    } else if (element.parentElement) {
      return findParentATag(element.parentElement)
    }
    return undefined
  }

  const eventTarget = event.target as HTMLElement

  const controlSelectors = ['collection', 'more-btn', 'action-menu']

  const isControlButton = controlSelectors.some((selector) => {
    return eventTarget.className?.includes(selector)
  })

  // Ignore controls on the card, such as the "Add to Favorites"
  // and action menu buttons.
  if (isControlButton) {
    return
  }
  const card = findParentATag(eventTarget)
  if (!card) {
    return
  }
  const docUrl = card.getAttribute('href')
  if (!docUrl || !/book|article|podcast|episode/.test(docUrl)) {
    throw new Error('Expected doc card element. Unexpected UI element found.')
  }
  // Debugging
  // console.table({
  //   'Referrer Block': referrerBlockTitle,
  //   'Referrer Page Name': referrerUrl,
  //   'Doc URL': docUrl,
  //   'Doc Title': card.querySelector('[class*="__title"]')?.innerHTML,
  // })
  _track(
    'Doc View',
    {
      // Event data
      'Referrer Block': referrerBlockTitle,
      'Referrer Page Name': referrerUrl,
      'Doc URL': docUrl,
      'Doc Title': card.querySelector('[class*="__title"]')?.innerHTML,
    },
    {
      // People set data
      'Last Doc Open From Block': new Date().toISOString(),
    },
    {
      // People incremental data
      'Doc Opens From Block': 1,
    },
  )
}

export function trackOnboardingPageView(
  page: string,
  choices?: QuestionnaireUserChoices,
): void {
  _track('Onboarding Page View', {
    Page: page,
    Choices: choices,
  })
}

/**
 * Track language switch.
 *
 * Args:
 *    language: the language user switched to.
 */
export function trackLanguageSwitch(language: string): void {
  _track(
    'Language Switch',
    {
      // Event data
      language: language,
    },
    {
      // People set data
      'Last Language Switch': new Date().toISOString(),
    },
    {
      // People incremental data
      'Language Switches': 1,
    },
  )
}

/**
 * Track the event and optionally set people properties.
 *
 * Minimal example:
 *
 *   _track('My Event')
 *
 * Full example:
 *
 *   _track(
 *     // Event to track.
 *     'Select Book Category',
 *     // Event data.
 *     {
 *       category: tag,
 *     },
 *     // Data for mixpanel.people.set({..}).
 *     {
 *       'Last Book Category Select': new Date().toISOString(),
 *     },
 *     // Data for mixpanel.people.increment({..}).
 *     {
 *       'Book Category Selected': 1,
 *     },
 *     // Callback to execute after tracking.
 *     () => {
 *       console.log('done')
 *     }
 *   )
 */
export function _track(
  event: string,
  eventData?: Record<string, unknown>,
  peopleSetData?: Record<string, unknown>,
  peopleIncrementData?: Record<string, unknown>,
  callback?: (err?: any) => void,
): void {
  if (!hasMixpanel()) {
    return
  }

  _setSuperProperties()
    .then(() => {
      baseTrack(event, eventData, peopleSetData, peopleIncrementData, callback)
    })
    .catch((error) => {
      Sentry.captureException(error)
      baseTrack(event, eventData, peopleSetData, peopleIncrementData, callback)
    })
}

/**
 * Async version of the '_track' call.
 *
 * Note: this is better in terms of logic - most of the time we don't await the
 * tracking is it is not super essentaial, also this is not good for tests
 * - the calls left without wating for them and might be executed at
 * the point where the call will fail.
 * For example, in the trackBookReadTime test, where the tracking call is
 * invoked from the beforeRouteLeave it resulted in `book` parameter being
 * null (although we defenitely set it when we invoke the method).
 * Adding `async` helped solving it, but we still have many such cases with
 * other tracking calls.
 */
export async function _trackAsync(
  event: string,
  eventData?: Record<string, unknown>,
  peopleSetData?: Record<string, unknown>,
  peopleIncrementData?: Record<string, unknown>,
): Promise<void> {
  await _setSuperProperties()
  return await baseTrackAsync(
    event,
    eventData,
    peopleSetData,
    peopleIncrementData,
  )
}

/**
 * Set Mixpanel super properties.
 */
export async function _setSuperProperties(): Promise<void> {
  if (!hasMixpanel()) {
    return
  }

  const deviceInfo = await platform.deviceInfo()

  const superProperties: Record<string, string> = {
    Platform: deviceInfo.platform,
    'Native App Version': deviceInfo.appVersion,
    'Device Model': deviceInfo.model,
    'Device OS': deviceInfo.osVersion,
    'User Agent': navigator.userAgent,
  }

  // `router()` is `undefined` on public pages.
  const currentRoute = router()?.currentRoute?.value?.name
  if (currentRoute) {
    superProperties['Current Route'] = currentRoute.toString()
  }

  mixpanel.register(superProperties)
}
