
import { defineComponent } from 'vue'
import CircleSpinner from '@/components/ui/CircleSpinner.vue'

export default defineComponent({
  name: 'AppButton',
  components: {
    CircleSpinner,
  },
  props: {
    /**
     * Reduce the size of the button
     */
    dense: Boolean,
    /**
     * Place the button in a disabled state
     */
    disabled: Boolean,
    /**
     * Set the button to a disabled state
     */
    loading: Boolean,
    /**
     * The iconfont icon to use
     */
    icon: {
      type: String,
      default: undefined,
    },
    /**
     * Use the outlined design variant
     */
    outlined: Boolean,
    /**
     * Use the primary-alt design variant
     */
    primaryAlt: Boolean,
    /**
     * Use the danger design variant
     */
    danger: Boolean,
    /**
     * Anchor tag attribute that transform components into `<a href="" />`
     */
    href: {
      type: String,
      default: undefined,
    },
    /**
     * Element tag to use for the button. Defaults to `button`
     */
    tag: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    classes(): Record<string, boolean> {
      return {
        'app-btn': true,
        'app-btn--dense': this.dense,
        'app-btn--disabled': this.disabled,
        'app-btn--loading': this.loading,
        'app-btn--outlined': this.outlined,
        'app-btn--primary-alt': this.primaryAlt,
        'app-btn--danger': this.danger,
      }
    },
    elementTag(): string {
      const tag = (this.href && 'a') || this.tag || 'div'
      return tag
    },
  },
})
