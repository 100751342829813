import { ValidationState } from '@/helpers/form'
import { backend } from '@/services/backend'
import { convertError } from './error'
import { Sentry } from '@/services/sentry'
import {
  CustomList,
  CustomListListItem,
  ListItem,
  ListItemType,
  DocListItem,
  PublicInterface,
} from './interfaces'
import { DocCustomListsView } from './doc.custom.lists'
import { Mixpanel } from '@/analytics/mixpanel'
import { backLink } from './backlink'
import { Router } from 'vue-router'
import { DocsView } from './doc'
import { DocsListView } from './docs.list'
import { resetCustomListsCache } from '@/helpers/cache'

/**
 * Custom Lists listing logic used both by block and listing page.
 */
export class CustomListsView implements DocsListView {
  private _docs: CustomList[]

  constructor(docs: CustomList[]) {
    this._docs = docs
  }

  get filteredDocs(): CustomList[] {
    return this._docs
  }

  get dataLength(): number {
    return this._docs.length
  }

  removeDoc(docId: string): void {
    const indexToRemove = this._docs.findIndex((doc) => doc.id === docId)
    this._docs.splice(indexToRemove, 1)
  }

  /**
   * Get up to 3 cover images from the list's docs.
   */
  coverImages(doc: CustomList): string[] {
    return doc.docs
      .slice(0, 3)
      .map((doc) => doc.cover_image)
      .filter(Boolean) as string[]
  }

  docTypeName(): ListItemType {
    return 'custom_list'
  }

  setShowLimit(): void {
    // Not implemented for custom lists
  }

  isDocSortVisible(): boolean {
    return false
  }

  /**
   * Link to the individual list page.
   */
  startLinkParams(doc: ListItem): Record<string, unknown> {
    return {
      name: 'customList',
      params: {
        url_slug: doc.url_slug,
      },
    }
  }
}

export class CustomListForm {
  title: string = ''
  docCustomListView?: PublicInterface<DocCustomListsView>
  isLoading = false

  private validation = new ValidationState()

  constructor(docCustomListView?: PublicInterface<DocCustomListsView>) {
    this.docCustomListView = docCustomListView
  }

  get isCreateForm(): boolean {
    return this instanceof CreateCustomListForm
  }

  get isRenameForm(): boolean {
    return this instanceof RenameCustomListForm
  }

  get isConfirmBtnDisabled(): boolean {
    return this.isLoading || !this.title
  }

  get formTitle(): string {
    return this.isCreateForm ? 'Create New List' : 'Rename List'
  }

  get confirmButtonLabel(): string {
    return this.isCreateForm ? 'Create List' : 'Rename List'
  }

  /**
   * A list of errors in the latest attempt at submitting the form.
   */
  get errors(): string[] {
    if (!this.validation.isValidated) {
      return []
    }

    return this.validation.getAllErrors()
  }

  /**
   * Submit the form and handle errors.
   *
   * @returns `true` if the request was successful
   *   and `false` if it wasn't. We use this return
   *   value to know when to close the form popup.
   */
  async submitForm(): Promise<boolean> {
    this.validation.reset()

    try {
      this.isLoading = true

      await this._submitForm()
      await resetCustomListsCache(this.docCustomListView?.doc.doc_type)

      return true
    } catch (error: any) {
      return this._handleSubmitErrors(error)
    } finally {
      this.isLoading = false
    }
  }

  /*
   * Send the backend request to submit the form.
   * Should be overridden in subclasses.
   */
  protected async _submitForm(): Promise<void> {
    throw new Error('Not implemented')
  }

  /**
   * Parse the response errors to display it in the form.
   */
  private _handleSubmitErrors(rawError: any): boolean {
    const parsedError = convertError(rawError)

    if (!parsedError.isValidationError()) {
      Sentry.captureException(rawError)
      this.validation.showMessage('title', 'Unexpected error.')
      return false
    }

    if (
      parsedError.getOne('title') ===
        'The user already has a custom list with the same title' ||
      parsedError.getOne('url_slug') ===
        'The user already has a custom list with the same url_slug'
    ) {
      this.validation.showMessage(
        'title',
        'You already have a list with this title.',
      )
    } else {
      this.validation.show(parsedError)
    }

    return false
  }

  async addDocToList(list: CustomList): Promise<void> {
    await this.docCustomListView?.toggleList(list, true, true)
  }

  clearState(): void {
    this.title = ''
    this.validation.reset()
    this.docCustomListView?.clearState()
  }
}

export class CreateCustomListForm extends CustomListForm {
  navigateToCreatedPage: boolean
  router?: Router

  constructor(
    docCustomListView?: PublicInterface<DocCustomListsView>,
    navigateToCreatedPage: boolean = false,
    router?: Router,
  ) {
    super(docCustomListView)
    this.navigateToCreatedPage = navigateToCreatedPage
    this.router = router
  }

  protected async _submitForm(): Promise<void> {
    const response = await backend.createCustomList(this.title)
    Mixpanel.trackCreateCustomList(response)

    // If this view is being used in a Doc card,
    // add the doc to the newly created Custom List.
    if (this.docCustomListView) {
      await this.addDocToList(response)
    }

    if (this.navigateToCreatedPage && this.router) {
      this.router.push({
        name: 'customList',
        params: { url_slug: response.url_slug },
      })
    }
  }
}

export class RenameCustomListForm extends CustomListForm {
  initialTitle: string
  list: CustomListListItem
  router: Router
  isSingleListPage: boolean

  constructor(
    list: CustomListListItem,
    router: Router,
    isSingleListPage: boolean,
  ) {
    super()
    this.list = list
    this.initialTitle = list.title
    this.title = list.title
    this.router = router
    this.isSingleListPage = isSingleListPage
  }

  protected async _submitForm(): Promise<void> {
    const response = await backend.renameCustomList(this.list, this.title)
    Mixpanel.trackRenameCustomList(response, this.initialTitle)

    if (this.isSingleListPage) {
      // The URL slug of the page changes when we rename it, so we have
      // to update it and refresh it.
      this.router.replace(`/app/list/${response.url_slug}`)
    } else {
      this.initialTitle = response.title

      this.list.title = response.title
      this.list.url_slug = response.url_slug
    }
  }

  get isConfirmBtnDisabled(): boolean {
    return super.isConfirmBtnDisabled || this.title === this.initialTitle
  }

  clearState(): void {
    super.clearState()
    this.title = this.initialTitle
  }
}

/**
 * Delete the given `list` and go back to the previous page if `goBack` is `true`.
 */
export async function deleteCustomList(
  list: CustomListListItem,
  goBack: boolean = false,
  listsView?: CustomListsView,
): Promise<boolean> {
  try {
    await backend.deleteCustomList(list)
    Mixpanel.trackDeleteCustomList(list)
    await resetCustomListsCache()

    if (goBack) {
      backLink.value.goBack()
    } else if (listsView) {
      listsView.removeDoc(list.id)
    }

    return true
  } catch (error: any) {
    Sentry.captureException(error)
    return false
  }
}

/**
 * Remove the given `doc` from the `list`, and update `docsView` to reflect the changes in the frontend.
 */
export async function removeDocFromList(
  doc: DocListItem,
  docsView: DocsView,
  list: CustomListListItem,
): Promise<void> {
  try {
    const response = await backend.updateCustomLists(doc, {
      [list.url_slug]: false,
    })

    await docsView.updateDocCustomLists(doc, response.custom_lists)
    await resetCustomListsCache(doc.doc_type)
  } catch (error: any) {
    Sentry.captureException(error)
  }
}
