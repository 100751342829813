import { ComponentPublicInstance } from 'vue'

import { assertNotNull } from '@/helpers/typing'
import { backend } from '@/services/backend'
import { auth } from '@/services/auth'
import {
  resetHomeCache,
  updateBooksCache,
  updateHomeCache,
} from '@/helpers/cache'

import {
  Annotation,
  AnnotationContent,
  AnnotationRange,
  AnnotationWithContent,
  DocExportStats,
  Content,
  DocType,
  Doc,
  DocAudio,
  DocListItem,
  Publisher,
  Commentaries,
  ApiResultDocs,
  EmbeddedDocGroup,
  HighlightType,
  Bookmark,
  CustomListListItem,
} from './interfaces'

import { DocReadingSettings } from '@/models/doc.settings'
import { DocControls } from './doc.controls'
import { BookTutorial } from './doc.tutorial'
import { DocTocView } from './doc.toc'
import { getDownloadedBooks } from '@/services/native.storage'
import { DocsListView } from './docs.list'
import { ListingPage, listingPageByType, PageType } from './docs.listing'
import { Search } from './doc.search'
import { RouteLocationNormalized } from 'vue-router'
import { OpenQuestionsView } from './book'
import { ReadingProgress } from '@/services/readingProgress'
import { Mixpanel } from '@/analytics/mixpanel'
import { meta } from '@/services/meta'
import { SCROLL_SHOW_THRESHOLD_MOBILE } from '@/init/settings'
import { Sentry } from '@/services/sentry'
/**
 * The initial value of DocsView._showLimit, which is the number of docs we
 * should initially load into the page.
 */
export const SCROLL_INITIAL_SHOW_LIMIT = 30

/**
 * The number of new docs we should load each time we add to the page.
 * 10 more documents seems to be enough on any screen size.
 */
export const SCROLL_BATCH_SIZE = 10

/**
 * Annotation data and reference to parent content.
 */
export class AnnotationView implements Annotation {
  private _content: AnnotationContent
  private _annotation: Annotation

  public id: string
  public quote: string
  public ranges: AnnotationRange[]
  public is_public: boolean
  public created: string
  public updated: string
  public highlight_type: HighlightType

  constructor(content: AnnotationContent, annotation: Annotation) {
    this._annotation = annotation
    this._content = content
    this.id = annotation.id
    this.quote = annotation.quote
    this.ranges = annotation.ranges
    this.is_public = annotation.is_public
    this.created = annotation.created
    this.updated = annotation.updated
    this.highlight_type = annotation.highlight_type
  }

  get content(): AnnotationContent {
    return this._content
  }

  get text(): string {
    return this._annotation.text ? this._annotation.text : ''
  }

  /**
   * Settter for text.
   *
   * We have getter / setter for test to make it updatable.
   * When we edit the note, we also want to update the data source for highlights,
   * which is the array of annotations in the `_content`.
   */
  set text(value: string) {
    this._annotation.text = value
  }

  /**
   * Returns chapter link params.
   */
  get contentLink(): Record<string, unknown> {
    if (this._content.doc.doc_type === 'book') {
      return {
        name: 'book.page',
        params: {
          url_slug: this._content.doc.url_slug,
          page_url_slug: this._content.url_slug,
        },
        query: {
          highlight: this._annotation.id,
        },
      }
    }
    if (this._content.doc.doc_type === 'podcast_episode') {
      return {
        name: 'podcast.page',
        params: {
          url_slug: this._content.doc.url_slug,
          page_url_slug: this._content.url_slug,
        },
      }
    }
    return {
      name: 'article',
      params: {
        url_slug: this._content.doc.url_slug,
      },
    }
  }

  /**
   * Returns chapter link params.
   */
  get publicLink(): Record<string, unknown> {
    return {
      name: 'public_highlight',
      params: {
        book_url_slug: this._content.doc.url_slug,
        highlight_id: this._annotation.id,
      },
    }
  }

  /**
   * Returns chapter title.
   */
  get contentTitle(): string {
    if (this._content.doc.doc_type === 'book') {
      return this._content.doc.title + ': ' + this._content.title
    }
    return this._content.doc.title
  }

  /**
   * Returns doc title.
   */
  get docTitle(): string {
    return this._content.doc.title
  }

  /**
   * Returns author.
   */
  get author(): string {
    return this._content.doc.author
  }

  /**
   * Returns cover image.
   */
  get cover_image(): string | undefined {
    return this._content.doc.cover_image
  }

  /**
   * Returns doc_type.
   */
  get doc_type(): string {
    return this._content.doc.doc_type
  }

  /**
   * Returns content id.
   */
  get contentId(): string {
    return this._content.id
  }
}

/**
 * Convert highlights objects (AnnotationWithContent) array
 * to highlights views (AnnotationView) array.
 */
export function highlightsToViews(
  highlights: AnnotationWithContent[],
): AnnotationView[] {
  const views: AnnotationView[] = []
  for (let i = 0; i < highlights.length; i++) {
    const highlight = highlights[i]
    const view = new AnnotationView(highlight.content, highlight)
    views.push(view)
  }

  return views
}

/**
 * Chapter piece data and logic to display content in components.
 */
export class ContentView implements Content {
  protected _doc: DocView

  id: string
  doc_id: string
  title: string
  text: string
  content_type: string
  order: number
  url_slug: string
  highlights: Annotation[]

  commentaries?: Commentaries

  accessed: string
  completed: string | null

  constructor(doc: DocView, content: Content) {
    this._doc = doc
    this.id = content.id
    this.doc_id = doc.id
    this.title = content.title
    this.text = content.text
    this.content_type = content.content_type
    this.order = content.order
    this.url_slug = content.url_slug
    this.highlights = content.highlights
    this.accessed = content.accessed
    this.completed = content.completed
    this.commentaries = content.commentaries
  }

  /**
   * Returns CSS class for page icon in the sidebar.
   */
  get iconCssClass(): string {
    return ''
  }

  public get doc(): DocView {
    return this._doc
  }

  public asSerialisable(): AnnotationContent {
    const doc = {
      id: this.doc.id,
      title: this.doc.title,
      author: this.doc.author,
      cover_image: this.doc.cover_image,
      url_slug: this.doc.url_slug,
      doc_type: this.doc.doc_type,
    }
    return {
      id: this.id,
      doc: doc,
      title: this.title,
      content_type: this.content_type,
      order: this.order,
      url_slug: this.url_slug,
    }
  }

  /**
   * This is a shorthand to get `doc.isChapterEOC`.
   */
  public get isChapterEOC(): boolean {
    return this.doc.isChapterEOC
  }
}

/**
 * Chapter piece data and logic to display content in components.
 */
export class ChapterView extends ContentView implements Content {
  /**
   * Returns CSS class for page icon in the sidebar.
   */
  get iconCssClass(): string {
    return 'fas fa-book text-gray'
  }
}

/**
 * Returns CTA block item for Books and Articles views.
 */
export function createCtaBlockItem(idx?: number): DocListItem {
  if (idx === null || idx === undefined) {
    idx = 0
  }
  // Create Doc with all of the required fields
  // title: 'CTA' is used to check whether this is a CTA block, don't change it
  return {
    id: `call-to-action-id-${idx}`,
    title: 'CTA',
    author: 'CTA Author',
    cover_image: '',
    url_slug: 'call-to-action',
    public_url_slug: 'call-to-action-public',
    is_first_doc: false,
    is_free: true,
    is_ai: false,
    amazon_link: '',
    amazon_image: '',
    doc_type: 'article',
    forum_id: 'forum-cta-id',
    tags: [''],
    hidden_tags: [''],
    list: null,
    custom_lists: [],
    is_favorite: false,
    popularity_read_count: 0,
    popularity_user_count: 0,
    created: '2020-12-02',
    first_published_at: '2020-12-02',
    accessed: '2020-12-03',
    progress: 0,
    audio_position: 0,
    position_chapter: 'cta-chapter-url',
    position_percent: 0,
    has_audio: false,
  }
}

/**
 * Document data and logic to display doc in components.
 */
export class DocView implements Doc {
  protected _vm: ComponentPublicInstance
  protected _pages: ContentView[]

  public id!: string
  public title!: string
  public author!: string
  public blurb?: string
  public cover_image?: string
  public url_slug!: string
  public public_url_slug!: string
  public is_free!: boolean
  public amazon_link!: string
  public amazon_image!: string
  public tags!: string[]
  public hidden_tags!: string[]

  public doc_type!: DocType

  // List on the Home page (reading, finished, removed).
  public list!: string | null
  public custom_lists!: CustomListListItem[]
  // Marked as 'favorite' by the user.
  public is_favorite!: boolean

  // Reading settings.
  public settings: DocReadingSettings

  public has_audio!: boolean

  public doc_export_stats?: DocExportStats
  public audio?: DocAudio
  public publisher?: Publisher

  // Completion progress, in percents.
  public progress!: number
  // Reading position, chapter url slug.
  public position_chapter!: string
  public position_percent!: number

  // Saved audio position
  public audio_position!: number

  public original_publish_date?: string
  public original_url?: string
  public original_words?: number
  public summary_words?: number

  public forum_id!: string

  public popularity_user_count!: number
  public popularity_read_count!: number
  public created!: string
  public first_published_at!: string
  public accessed: string | null = null
  public doc_group?: EmbeddedDocGroup
  public is_ai: boolean = false
  public is_first_doc: boolean = false

  public content: Content[]

  public controls: DocControls
  public tutorial: BookTutorial | null
  public toc: DocTocView | null

  /**
   * Number of the current page displayed in the UI.
   */
  protected _pageNum: number
  protected _pagesMap: { [url_slug: string]: ContentView }

  public readingStartTime: number = 0
  readingProgress: ReadingProgress | null = null
  // For tests, to permanently disable timers,
  // can be set by `disableTimers()` method call.
  timersDisabled: boolean = false

  async updateReadTime(): Promise<void> {
    // Updates the reading time, to be used when we navigate away from this page.
    const timeDiff = Math.floor((Date.now() - this.readingStartTime) / 1000)
    await Mixpanel.trackDocReadTime(this, timeDiff)
  }

  disableTimers(): void {
    this.timersDisabled = true
    if (this.readingProgress) {
      assertNotNull(this.readingProgress).clearTimers()
    }
  }

  restartTracking(): void {
    if (!this.timersDisabled) {
      assertNotNull(this.readingProgress).restartTracking()
    }
  }

  constructor(vm: ComponentPublicInstance, doc: Doc) {
    this._vm = vm
    _copyDoc(doc, this)

    this.content = doc.content
    this._pages = new Array<ContentView>()
    this._pagesMap = {}
    doc.content.forEach((content: any) => {
      const contentView = this.createChapter(content)
      this._pages.push(contentView)
      this._pagesMap[content.url_slug] = contentView
    })

    this._pageNum = 0

    this.settings = new DocReadingSettings()
    this.controls = new DocControls(this, this._vm)
    // Create tutorial and toc (optional, present only for book)
    this.tutorial = this.createTutorial()
    this.toc = this.createToc()

    this.readingStartTime = Date.now()
  }

  async initPage(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    loadDocument: (route: RouteLocationNormalized) => Promise<void>,
  ): Promise<void> {
    // Called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    //
    // The `if (this.book)` below is for tests, I didn't observe this.book
    // being empty here in a real app.
    // Looks like in tests, there is no await for beforeMount, so we can get
    // here before the `loadBook` call.
    if (to.params.url_slug === from.params.url_slug) {
      // In this case, we're navigating within the same book.
      // We use this hook to change the current active page in the book model.
      if (this) {
        this.getPageFromRoute(to)

        await this.scrollToTop()

        // Restart progress timer after moving to another book page.
        if (!this.timersDisabled) {
          assertNotNull(this).restartTracking()
        }
      }
    } else {
      // Destroy the current ReadingProgress instance so we don't have two instances
      // on the next book.
      if (this && this.readingProgress) {
        this.readingProgress.destroy()
      }

      // `to` is a new book's route, therefore this is the end of this book's
      // session, and we need to log the reading time.
      await this.updateReadTimeAndSavePosition()
      // Since beforeMount() isn't called, we call loadDocument() here to load
      // the new book's data.
      await loadDocument(to)
    }
  }

  initDocument(restorePosition: boolean): void {
    this.readingProgress = new ReadingProgress(
      assertNotNull(this),
      restorePosition,
    )

    // Set the current page from current route.
    meta.setAppTitle(assertNotNull(this).title)

    // Start the 20 sec timer to add the document to the user library
    // or update the access time.
    this.restartTracking()
  }

  /**
   * Update the read time on mixpanel and save the reading position.
   */
  async updateReadTimeAndSavePosition(): Promise<void> {
    await Promise.all([
      this.saveReadingPositionFromScrollPosition(),
      this.updateReadTime(),
    ])
  }

  createTutorial(): BookTutorial | null {
    return null
  }

  createToc(): DocTocView {
    return new DocTocView(this)
  }

  /**
   * Keep the sidebar and header open.
   *
   * Depending on the scrolling position, we want to show the header at the top
   * of the page and toolbar at the bottom of the page.
   *
   * This function disallows hiding the header and toolbar when scroll is at top
   * or bottom of the page.
   */
  keepSidebarAndHeaderOpen(): boolean {
    const distanceToBottom =
      this.appEl.scrollHeight - window.innerHeight - this.appEl.scrollTop
    if (
      this.appEl.scrollTop < SCROLL_SHOW_THRESHOLD_MOBILE ||
      distanceToBottom < SCROLL_SHOW_THRESHOLD_MOBILE
    ) {
      return true
    }
    return false
  }

  get pageNum(): number {
    return this._pageNum
  }
  /**
   * Returns any page link data.
   */
  pageLink(pageIdx: number): any {
    const exists = pageIdx < this._pages.length && pageIdx >= 0

    const res = {
      exists: exists,
      title: exists ? this._pages[pageIdx].title : '',
      params: {
        name: this.routeNames().page,
        params: {
          url_slug: this.url_slug,
          page_url_slug: exists ? this._pages[pageIdx].url_slug : '',
        },
      },
    }

    return res
  }

  /**
   * Factory function to create chapter object
   * based on content_type value.
   */
  protected createChapter(content: Content): ContentView {
    return new ContentView(this, content)
  }

  /**
   * Document pages.
   */
  get pages(): ContentView[] {
    return this._pages
  }

  /**
   * Returns all bookmarks from the doc.
   */
  get bookmarks(): Bookmark[] {
    return this._pages.flatMap((page) => {
      const highlights = page.highlights.filter(
        (highlight) => highlight.highlight_type === 'bookmark',
      )
      return highlights.map((highlight) => {
        return {
          ...highlight,
          page_url_slug: page.url_slug,
        }
      })
    })
  }

  /**
   * Document pages map.
   *
   * Should be overridden in subclasses.
   * For example, in the book we have a map like this:
   *
   * return {
   *   'community': 'book.community',
   *   'thread': 'book.thread',
   *   'edit_thread': 'book.edit_thread',
   *   'highlights': 'book.highlights',
   *   'export': 'book.export',
   *   'page': 'book.page',
   *   'preview': 'book.preview',
   * }
   */
  routeNames(): Record<string, string> {
    throw new Error('Not implemented')
  }

  /*
   * Return an object with the values for the reading button
   * Should be overridden in subclasses.
   */
  startReadingButton(): any {
    throw new Error('Not implemented')
  }

  /*
   * Returns the title for the DocPreview page 'About ${Book | Episode}
   * Should be overridden in subclasses.
   */
  get aboutTitle(): string {
    throw new Error('Not implemented')
  }

  /*
   * Return a boolean, that determines if we show the download button on the DocPreviewPage.
   *  Should be overridden in subclasses.
   */
  get showDownload(): boolean {
    throw new Error('Not implemented')
  }

  /*
   * Returns the title for the full summary item of the reading page TOC.
   * Should be overridden in subclasses.
   */
  public get fullSummaryItemText(): string {
    throw new Error('Not implemented')
  }

  /*
   * Current selected page.
   */
  get currentPage(): ContentView {
    return this._pages[this._pageNum]
  }

  /*
   * Current exercise for the selected page.
   */
  get currentExercise(): OpenQuestionsView {
    if (!this.isCurrentPageOpenQuestions) {
      throw new Error('Can not return current page as open questions')
    }
    return this._pages[this._pageNum] as OpenQuestionsView
  }

  /**
   * Returns true if specified `_pageNum` equals to the
   * current selected page number.
   */
  isCurrentPage(page: ContentView): boolean {
    const route = this._vm.$router.currentRoute.value
    if (route.name !== this.routeNames().page) {
      // This method is also called when we reach the doc community,
      // in that case there is no 'page_url_slug' in params and we
      // return "false" for doc pages.
      return false
    }
    return this._pageNum === page.order
  }

  isCurrentFirstPage(): boolean {
    return (
      this._pageNum === 0 &&
      this._vm.$router.currentRoute.value.name === this.routeNames().page
    )
  }

  isCurrentReadingPage(): boolean {
    return (
      this._pageNum > 0 &&
      this._vm.$router.currentRoute.value.name === this.routeNames().page
    )
  }

  /**
   * Returns true current page is 'community'.
   */
  isCurrentCommunity(): boolean {
    return (
      this._vm.$router.currentRoute.value.name === this.routeNames().community
    )
  }

  isCurrentHighlights(): boolean {
    return (
      this._vm.$router.currentRoute.value.name === this.routeNames().highlights
    )
  }

  /*
   * Returns true if this content has 'chapter' type.
   */
  get isCurrentPageChapter(): boolean {
    return this.currentPage.content_type === 'chapter'
  }

  /**
   * Returns true current page is 'doc_export'.
   */
  isCurrentPageExport(): boolean {
    return this._vm.$router.currentRoute.value.name === this.routeNames().export
  }

  /*
   * Returns true if this content has 'open_questions' type.
   */
  get isCurrentPageOpenQuestions(): boolean {
    return (
      this.currentPage.content_type === 'open_questions' ||
      this.currentPage.content_type === 'quiz'
    )
  }

  /*
   * Returns true if this content has 'open_questions' type.
   */
  isPageOpenQuestions(page: ContentView): boolean {
    return (
      page.content_type === 'open_questions' || page.content_type === 'quiz'
    )
  }

  /**
   * Updates current page number depending on the route.
   * So when we click the `/book/book-id/page/3` link,
   * for example, the `this._pageNum` is set to 3.
   *
   * Initially we set it to 0 (the first page).
   *
   * This is called from a root BookSummaryPage component
   * after loading the book and on route updates.
   */
  public getPageFromRoute(route: RouteLocationNormalized): void {
    if ('page_url_slug' in route.params && route.params.page_url_slug) {
      const page = this._pagesMap[route.params.page_url_slug as string]
      if (page) {
        this._pageNum = page.order
      } else {
        console.log('Wrong route, redirect to the first page.')
        // Wrong route, redirect to the first page.
        // We do the redirect here and not just set the this._pageNum
        // so we don't leave the non-existing URL in the browser
        // address field.
        this._vm.$router.push({ name: this.routeNames().page })
      }
    } else {
      this._pageNum = 0
    }
  }

  /**
   * Returns true if specified `_pageNum` is marked as completed.
   */
  isCompletedPage(page: ContentView): boolean {
    return page.completed !== null && page.completed !== undefined
  }

  /**
   * Returns true if all chapters under full summary are completed.
   */
  get isCompletedFullSummary(): boolean {
    for (let idx = 1; idx < this._pages.length; idx++) {
      const page = this._pages[idx]
      if (page.completed === null) {
        return false
      }
    }
    return true
  }

  /**
   * Returns true if end-of-content should be rendered on the chapter page.
   */
  public get isChapterEOC(): boolean {
    if (this.is_free) {
      // No end-of-content if the doc is free.
      return false
    }
    // If plan is free - render end-of-content, otherwise - not.
    return auth.isFreePlan()
  }

  /**
   * Returns true if the current page is the doc preview page.
   */
  public get isPreviewPage(): boolean {
    const currentPath = this._vm.$router.currentRoute.value.path

    // Check if the current path ends with `/preview`
    return Boolean(currentPath.match(/\/preview$/))
  }

  public get hasHighlights(): boolean {
    return this.highlights.length > 0
  }

  /**
   * Get annotations.
   */
  public get highlights(): AnnotationView[] {
    const result: AnnotationView[] = []
    for (let i = 0; i < this._pages.length; i++) {
      const item = this._pages[i]
      for (let j = 0; j < item.highlights.length; j++) {
        const h = item.highlights[j]
        result.push(new AnnotationView(item, h))
      }
    }
    return result
  }

  /**
   * Returns previous page link data.
   */
  get prevLink(): Record<string, unknown> {
    const exists = this._pageNum > 0
    return {
      exists: exists,
      title: exists ? this._pages[this._pageNum - 1].title : '',
      params: {
        name: this.routeNames().page,
        params: {
          url_slug: this.url_slug,
          page_url_slug: exists ? this._pages[this._pageNum - 1].url_slug : '',
        },
      },
    }
  }

  /**
   * Return the link for "preview" page.
   */
  get previewLink(): Record<string, unknown> {
    return {
      params: {
        name: this.routeNames().preview,
        params: {
          url_slug: this.url_slug,
        },
      },
    }
  }

  /**
   * Returns next page link data.
   */
  get nextLink(): Record<string, unknown> | null {
    const exists = this._pageNum < this._pages.length - 1
    return {
      exists: exists,
      title: exists ? this._pages[this._pageNum + 1].title : '',
      params: {
        name: this.routeNames().page,
        params: {
          url_slug: this.url_slug,
          page_url_slug: exists ? this._pages[this._pageNum + 1].url_slug : '',
        },
      },
    }
  }

  /**
   * Start link for the book, according to the tracked reading position.
   */
  startLinkParams(): Record<string, unknown> {
    return {
      name: this.routeNames().page,
      params: {
        url_slug: this.url_slug,
        page_url_slug: this.position_chapter,
      },
    }
  }

  public async doneClick(): Promise<void> {
    throw new Error('Not implemented')
  }

  get doneText(): string {
    throw new Error('Not implemented')
  }

  /* Delete a highlight.
   *
   * Used in the highlights list on the book highlights page.
   * Looks through the document pages to find the given `highlight`
   * and remove it.
   * Sends a backend request to delete the highlight and removes it
   * from the highlights list to update the UI.
   */
  public async deleteHighlight(highlight: AnnotationView): Promise<Annotation> {
    for (const page of this._pages) {
      for (let i = 0; i < page.highlights.length; i++) {
        if (page.highlights[i].id === highlight.id) {
          const annotation = await backend.deleteHighlight(
            highlight.content,
            highlight,
          )
          page.highlights.splice(i, 1)
          return annotation
        }
      }
    }
    throw new Error('Error during deleting highlight from highlight list')
  }

  /* Copy a highlight.
   *
   * Copies highlight content to the clipboard.
   */
  public async copyHighlight(highlight: AnnotationView): Promise<void> {
    try {
      await navigator.clipboard.writeText(highlight.quote)
    } catch (err) {
      throw new Error('Error during copying highlight to clipboard')
    }
  }

  /**
   * This method saves doc progress by marking chapters completed.
   *
   * We consider the text chapter as completed: if we stay on the page
   * for 20 seconds AND click next / prev link to move to another chapter.
   * Special case: Done link on the last chapter.
   *
   * We consider exercise to be completed if it was submitted.
   */
  async saveProgress(): Promise<void> {
    if (auth.isFreePlan() && !this.is_free) {
      // If the user is on free plan and the doc is not free,
      // don't track the reading progress (because content is
      // not fully avaiable, part is hidden behind the end-of-content block).
      return
    }

    if (!this.isDocPage) {
      // Don't save reading progress for non-doc pages: preview, PDF download, discussion, etc.
      return
    }

    await backend.saveProgress(this.currentPage)
    // Note: it is better to return content from backend save progress
    // API, so we could just reuse backend logic here and do something
    // like content.completed = response.data.completed
    if (this.currentPage.content_type === 'chapter') {
      // Mark chapters as completed.
      const now = new Date()
      this.currentPage.completed = now.toISOString()
    }
  }

  /*
   * The `#app` HTML element, scrollable view.
   */
  get appEl(): HTMLElement {
    return assertNotNull(document.getElementById('app'))
  }

  /*
   * Restores reading position within the page. Scrolls to the saved percent
   * value of the page height. When a fragment link is set, it scrolls to the
   * element with the same id instead.
   */
  restoreReadingPosition(): void {
    // Ignore position restore if the route contains a fragment link.
    const fragment = this._vm.$router.currentRoute.value.hash
    if (Boolean(fragment)) {
      const element = document.getElementById(fragment.slice(1))

      // It does not suffice to check for truthiness, because TS
      // does not think of `Boolean(null)` as false...
      if (element !== null && Boolean(element)) {
        element.scrollIntoView()
      }
      return
    }

    // Do not restore position for the preview page
    const currentRoutePath = this._vm.$router.currentRoute.value.path
    if (currentRoutePath && currentRoutePath.includes('preview')) {
      return
    }

    // Do not restore position if chapter don't match
    if (this.position_chapter !== this.currentPage.url_slug) {
      return
    }

    const y = (this.appEl.scrollHeight * this.position_percent) / 100
    this.appEl.scroll(0, y)
  }

  /*
   * Get current reading position within the page.
   */
  getPositionChapterPercent(): number {
    // For Safari-based browsers scrollTop can be negative (#2617)
    // Just return 0 in this case
    if (this.appEl.scrollTop < 0) {
      return 0
    }
    return Math.round((this.appEl.scrollTop / this.appEl.scrollHeight) * 100)
  }

  /**
   * Scroll to the top of the page.
   *
   * We use this when going from a book page to the next. The default
   * behavior is to just preserve the scroll position, so the user
   * would navigate to the next page but the scroll would stay at
   * the bottom.
   */
  async scrollToTop(): Promise<void> {
    this.appEl.scrollTop = 0
  }

  /**
   * Check if current route is a doc page.
   *
   * We have some non-doc pages in the book, for example, PDF download page and
   * Discussions page. We don't need to save the progress on these pages.
   * @returns true: if the current route is a doc page
   */
  get isDocPage(): boolean {
    const currentRouteName = this._vm.$router.currentRoute.value.name
    return (
      currentRouteName === 'book.page' ||
      currentRouteName === 'podcast.page' ||
      currentRouteName === 'article'
    )
  }

  /**
   * Returns 'true' if the DocGroup that this Doc is a part of
   * can be followed.
   */
  isDocGroupFollowable(): boolean {
    return false
  }

  /**
   * Save doc reading position using the page scroll amount.
   */
  async saveReadingPositionFromScrollPosition(): Promise<void> {
    const position_percent = this.getPositionChapterPercent()
    await this.saveReadingPosition(position_percent)
  }

  /**
   * Save doc reading position
   */
  async saveReadingPosition(position_percent: number): Promise<void> {
    // Don't do anything if nothing changed
    if (
      this.position_chapter === this.currentPage.url_slug &&
      Math.round(this.position_percent - position_percent) === 0
    ) {
      return
    }

    // Don't save the position on non-doc pages: download PDF, discussions etc
    if (!this.isDocPage) {
      return
    }

    const isNewlyAccessed = this.accessed === null
    this.position_chapter = this.currentPage.url_slug
    this.position_percent = position_percent

    // Update cached book data
    // We update cache book data before making a network call
    // so that the cache is always up-to-date regardless of
    // whether the network call succeeds or fails (user goes offline in the process)
    const doc = {} as Doc
    _copyDoc(this, doc)
    // For books:
    await updateBooksCache(doc)
    // For home:
    if (!isNewlyAccessed) {
      await updateHomeCache(doc)
    } else {
      // For the newly accessed book we just reset the home cache (it's not very frequent event).
      await resetHomeCache()
    }

    const response = await backend.saveReadingPosition(
      this.currentPage,
      position_percent,
    )

    // Accessed has changed, so we recache the book
    this.accessed = response.accessed
    _copyDoc(this, doc)
    await updateBooksCache(doc)
    // Book is no longer newly accessed
    await updateHomeCache(doc)
  }

  async registerDocExport(): Promise<void> {
    if (this.doc_export_stats && !this.doc_export_stats.exported) {
      this.doc_export_stats.exported = true
      if (!this.is_free) {
        // Free books don't count into the PDF download limit
        this.doc_export_stats.limit.exports += 1
      }
    }
  }

  /**
   * Update doc export stats.
   */
  async getDocExportStats(): Promise<DocExportStats> {
    if (!this.doc_export_stats) {
      const data = await backend.getDocExportStats(this.id)
      this.doc_export_stats = data
      return data
    }
    return this.doc_export_stats
  }

  /**
   * Returns true if the end-of-content version of the community should be rendered.
   */
  public get isCommunityEOC(): boolean {
    if (this.is_free) {
      // No end-of-content if the book is free.
      return false
    }
    // If plan is free - render end-of-content, otherwise - not.
    return auth.isFreePlan()
  }

  /**
   * Move book to another list.
   */
  async moveTo(list: string): Promise<void> {
    const result = await backend.moveBookToList(this, list)
    this.list = result.list
  }

  /**
   * Returns true current page is 'thread'.
   */
  isCurrentThread(): boolean {
    return this._vm.$router.currentRoute.value.name === this.routeNames().thread
  }

  /**
   * Returns a CSS class used to identify to which
   * doc type a book reading page belongs to.
   */
  public get readingCssClass(): string {
    return `book-reading--${this.doc_type}`
  }

  /**
   * Returns true if the early access banner should be rendered
   */
  get showEarlyAccessBanner(): boolean {
    return this.is_ai
  }

  /**
   * Scroll to the given selector but keep an offset at the top so that the header
   * doesn't cover the element.
   */
  scrollToTargetAdjusted(selector: string): void {
    // The element we want to scroll to.
    const scrollToTarget = document.querySelector(selector)
    // The element that gets scrolled.
    const scrollElement = document.getElementById('app')
    if (scrollToTarget && scrollElement) {
      const headerOffset = 120
      const scrollToTargetPosition = scrollToTarget.getBoundingClientRect().top
      const offsetPosition =
        scrollToTargetPosition + scrollElement.scrollTop - headerOffset

      scrollElement?.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  /**
   * Scroll to highlight .
   */
  scrollToHighlight(highlight: Annotation): void {
    const selector = `[data-annotation-id="${highlight.id}"]`

    const highlightElement = document.querySelector(selector)
    if (highlightElement) {
      // We add a short timeout as the scroll / element position reported by
      // Safari is wrong initially, and such we'd scroll to a wrong position
      // without this delay.
      setTimeout(() => this.scrollToTargetAdjusted(selector), 1)
    } else {
      if (auth.isPaidPlan()) {
        // We only send this message if the user is on a paid plan, as this
        // is a rare case and we don't want to clutter the Sentry dashboard with the following flow:
        // 1. Started trial, made some highlights
        // 2. Cancelled trial
        // 3. Tries to go to the highlight, but it's behind the paywall
        Sentry.withScope((scope) => {
          scope.setExtra('excerpt_text', highlight.quote)
          scope.setExtra('original_text', highlight.text)
          Sentry.captureMessage(
            `Could not find highlight. Text not found in summary. Highlight ID: ${highlight.id}`,
          )
        })
      }
    }
  }

  /**
   * Returns true if the AI content label should be rendered.
   */
  get showAiLabel(): boolean {
    return this.is_ai
  }
}

/**
 * Copy source `doc` properties into `target`.
 *
 * We use this method when constructing the `DocView` object based
 * on the backend API response, to make sure that all expected fields
 * are present.
 *
 * Note: the need in copying could be removed by using some validation
 * library (https://github.com/sideway/joi ?).
 * In that case we could just validate and store the incoming `doc`
 * object in the `DocView` constructor and use it directly.
 *
 * Related discussion: https://github.com/shortformhq/main/pull/2966#discussion_r676190521.
 */
function _copyDoc(doc: DocListItem, target: Doc): void {
  target.id = doc.id
  target.title = doc.title
  target.author = doc.author
  target.blurb = doc.blurb
  target.cover_image = doc.cover_image
  target.url_slug = doc.url_slug
  target.public_url_slug = doc.public_url_slug
  target.is_free = doc.is_free
  target.amazon_link = doc.amazon_link
  target.amazon_image = doc.amazon_image
  target.tags = doc.tags
  target.hidden_tags = doc.hidden_tags
  target.has_audio = doc.has_audio
  target.doc_type = doc.doc_type
  target.popularity_read_count = doc.popularity_read_count
  target.popularity_user_count = doc.popularity_user_count
  target.created = doc.created
  target.first_published_at = doc.first_published_at
  target.accessed = doc.accessed

  target.forum_id = doc.forum_id

  target.list = doc.list
  target.custom_lists = doc.custom_lists || []
  target.is_favorite = doc.is_favorite
  target.progress = doc.progress
  target.position_chapter = doc.position_chapter
  target.position_percent = doc.position_percent
  target.audio_position = doc.audio_position

  target.original_publish_date = doc.original_publish_date
  target.original_url = doc.original_url
  target.original_words = doc.original_words
  target.summary_words = doc.summary_words

  target.audio = doc.audio
  target.publisher = doc.publisher
  target.doc_group = doc.doc_group
  target.is_ai = doc.is_ai
  target.is_first_doc = doc.is_first_doc
}

export enum DocSortOption {
  title = 'title',
  first_published_at = 'first_published_at',
  accessed = 'accessed',
  popular = 'popular',
  recommended = 'recommended',
  none = 'none',
}

/**
 * Book data and logic to display book in components.
 */
export class DocsView implements DocsListView {
  private _docs: DocListItem[]
  private _filteredDocs: DocListItem[]

  // Sort option, 'title' is server-side default
  protected _sort: DocSortOption = DocSortOption.title

  // List to filter books by ('reading', 'complete', 'removed')
  private _list: string
  // Filter books by custom_list.
  private _custom_list: string
  // Selected tag to filter books by.
  private _tag: string
  // Filter books by is_favorite flag.
  private _is_favorite: boolean | null
  // Show CTA blocks for free users>
  protected _showCtaBlock: boolean | null

  // All tags.
  private _tags: string[]

  // Show items limit, managed by DocsScroll class.
  // This is a performance optimization, so we do not have to
  // render all documents at once.
  // Instead we show first few documents and then render more
  // on scroll.
  private _showLimit: number
  // Hard limit, show only up to `_limit` docs.
  private _limit?: number = undefined

  protected _listingPage: ListingPage = listingPageByType(PageType.all)

  // Handles the doc search logic
  private _searcher: Search

  private _continueReading: boolean = false
  private _showActionsButton: boolean = false

  constructor(
    docs: DocListItem[],
    tags: string[],
    defaultSort: DocSortOption = DocSortOption.title,
    listingPage?: ListingPage,
  ) {
    this._docs = docs
    this._filteredDocs = []
    this._showLimit = SCROLL_INITIAL_SHOW_LIMIT
    this._limit = undefined

    // Create the searcher with the docs.
    this._searcher = new Search(this._docs, this.docTypeName())

    this._sort = defaultSort
    // List to filter the books by.
    this._list = ''
    this._custom_list = ''
    this._tags = tags
    this._tag = ''
    this._is_favorite = null
    this._showCtaBlock = true

    if (listingPage) {
      this._listingPage = listingPage
    }
  }

  public docTypeName(): DocType {
    throw new Error('Not implemented')
  }

  // Show free books at the top.
  protected showFreeAtTheTop(): boolean {
    return false
  }

  async init(): Promise<void> {
    await this._updateFilteredDocs()
    await this._listingPage.init(this)
  }

  static async searchDocsLimited(
    docType: DocType,
    searchTerm: string,
    limit: number = 100,
  ): Promise<DocListItem[]> {
    const response = await backend.getDocSearch(docType, searchTerm, limit)
    return response.data
  }

  /**
   * All documents.
   */
  get docs(): DocListItem[] {
    return this._docs
  }

  enableContinueReading(): void {
    this._continueReading = true
  }

  get continueReading(): boolean {
    return this._continueReading
  }

  enableActionsButton(): void {
    this._showActionsButton = true
  }

  get showActionsButton(): boolean {
    return this._showActionsButton
  }

  /**
   * Filtered by text / tag / list books.
   */
  get filteredDocs(): DocListItem[] {
    if (this._showLimit) {
      return this._filteredDocs.slice(0, this._showLimit)
    }
    return this._filteredDocs
  }

  get dataLength(): number {
    return this._filteredDocs.length
  }

  // Show free books at the top.
  get hasRecommendations(): boolean {
    return this._docs.some((doc) => doc.rank !== undefined)
  }

  /**
   * Set the limit of number of documents to show on the page.
   *
   * This also pre-loads the cover images for the next batch of documents, so
   * that they will be in the cache when the user scrolls down to actually load
   * them. (See #7442.)
   */
  setShowLimit(limit: number): void {
    // When we will show more docs than are currently on the page:
    if (this._showLimit < limit) {
      // Load images for the next docs so that they're in the cache when
      // the user scrolls down.
      if (limit === SCROLL_INITIAL_SHOW_LIMIT) {
        // This implies we are initializing the page. We should load the images
        // for the first `limit` docs.
        // (Alternative: we could only load the ones that aren't in the
        // viewport yet. We would need to do some calculation to determine
        // what's in the viewport and what's not.)
        this._loadImagesForDocs(this._filteredDocs.slice(0, limit))
      } else {
        // Otherwise, load the images for the docs we will be newly adding to
        // the page, plus the batch afterwards.
        this._loadImagesForDocs(
          this._filteredDocs.slice(
            limit - SCROLL_BATCH_SIZE,
            limit + SCROLL_BATCH_SIZE,
          ),
        )
      }
    }
    this._showLimit = limit
  }

  /**
   * Is the current view limited.
   *
   * Returns true if the ``_limit`` property was set.
   * This method is used to remove the Sort component from limited pages.
   */
  get isLimited(): boolean {
    return this._limit !== undefined
  }

  /**
   * Set documents limit.
   *
   * This method is used by special listing pages: new and popular books.
   * In order for the limit to work for, e.g. new books page with limit 20:
   * - sort docs by "Latest" (done from books view)
   * - get the first 20 documents from the sorted list
   * - invoke ``_updateFilteredDocs`` to propagate the limit to ``_filteredDocs`` field
   *
   */
  setDocsLimit(limit: number): void {
    this._limit = limit
    this._updateFilteredDocs()
  }

  get sortOption(): DocSortOption {
    return this._sort
  }

  /**
   * Update filtered books list.
   */
  protected async _updateFilteredDocs(): Promise<void> {
    const searchedDocs = await this._searcher.search()
    this._filteredDocs = this._sortDocs(this._filterDocs(searchedDocs))
    if (this._limit) {
      this._filteredDocs = this._filteredDocs.slice(0, this._limit)
    }
  }

  _filterDocs(docs: DocListItem[]): DocListItem[] {
    const searchTag = this._tag && this._tag.toLowerCase()
    const hasTag = (set: string[]): boolean =>
      set.some((tag) => tag.toLowerCase() === searchTag)

    const filtered = docs
      .filter(
        (doc) =>
          !this._list ||
          doc.list === this._list ||
          (this._list === 'viewed' && doc.position_percent > 0),
      )
      .filter(
        (doc) =>
          !this._custom_list ||
          doc.custom_lists.some((list) => list.url_slug === this._custom_list),
      )
      .filter(
        (doc) => !this._tag || hasTag(doc.tags) || hasTag(doc.hidden_tags),
      )
      .filter(
        (doc) =>
          this._is_favorite === null || doc.is_favorite === this._is_favorite,
      )

    return filtered
  }

  _compareTwoDocs(a: DocListItem, b: DocListItem): number {
    let field: keyof DocListItem = 'first_published_at'
    let isAscending = false

    if (a.doc_type === 'book' && a.is_ai !== b.is_ai) {
      // Demote AI Books
      return a.is_ai ? 1 : -1
    }

    // Don't sort docs - We keep the order from the backend result
    if (this._sort === DocSortOption.none) {
      return 0
    }

    if (this._sort === DocSortOption.title) {
      field = 'title'
      isAscending = true
    } else if (this._sort === DocSortOption.popular) {
      // Number of distinct users who have read at least one book page.
      field = 'popularity_user_count'
      if (a[field] === b[field]) {
        // If number of users is equal, use the total number of pages read.
        field = 'popularity_read_count'
      }
    } else if (this._sort === DocSortOption.accessed) {
      field = 'accessed'
    } else if (
      this._sort === DocSortOption.recommended &&
      this.hasRecommendations
    ) {
      field = 'rank'
      isAscending = true
    }

    // The DocListItem.accessed field can be nullable ("/api/books/"),
    // But it must be not null when we sorting by them ("/api/home/").
    let valA: string | number | Date = assertNotNull(a[field])
    let valB: string | number | Date = assertNotNull(b[field])

    if (field === 'first_published_at' || field === 'accessed') {
      valA = new Date(valA)
      valB = new Date(valB)
    }

    if (valA === valB) {
      return 0
    }
    if (isAscending) {
      return valA > valB ? 1 : -1
    } else {
      return valB > valA ? 1 : -1
    }
  }

  _sortDocs(docs: DocListItem[]): DocListItem[] {
    // Don't sort the free items if they are shown at the top.
    const freeFirst = this.showFreeAtTheTop()
    const topDocs = freeFirst ? docs.filter((doc) => doc.is_free) : []
    const docsToSort = freeFirst ? docs.filter((doc) => !doc.is_free) : docs

    return topDocs.concat(docsToSort.sort(this._compareTwoDocs.bind(this)))
  }

  /**
   *  Url for cover image to show on list page.
   */
  getItemCoverUrl(doc: DocListItem): string | undefined {
    if (doc.cover_image) {
      return cacheBust(doc.cover_image)
    }
  }

  /**
   * Load the cover image for each doc in `docs`.
   * This is to pre-load the book covers to make it look smoother when scrolling down.
   */
  _loadImagesForDocs(docs: DocListItem[]): void {
    docs.forEach((doc) => {
      /* The attributes (such as crossorigin) used here should be kept in
       *  sync with the attributes loaded by the vue templates. See the img
       *  tag in files:
       *
       *  app/components/article/Card.vue
       *  app/components/book/card/BookCard.vue
       */
      if (this.getItemCoverUrl(doc)) {
        const imageElement = document.createElement('img')
        imageElement.src = this.getItemCoverUrl(doc) as string
        imageElement.crossOrigin = 'anonymous'
      }
    })
  }

  /**
   * Injects CTA blocks into docs list.
   *
   * We need to display the CTAs more regularly than just once, so it has
   * more of an impact.
   *
   * For books we display it:
   * - on the second row
   * - after each 4 rows of books (18 books between CTA blocks)
   *
   * For articles we display it:
   * - on the third row
   * - after each 3 rows of articles (10 articles between CTA blocks)
   *
   * Artuments:
   *   position - start CTA block position, different for books and articles.
   *   gap - how many docs are between two CTA blocks.
   */
  injectCtaBlocks(position: number, gap: number): void {
    // Inject at least one CTA block, to the first position.
    // If position is less than number of Docs, the loop below will add the
    // first CTA block too.
    if (this._filteredDocs.length < position) {
      const ctaBlock = createCtaBlockItem()
      this._filteredDocs.splice(position, 0, ctaBlock)
      return
    }

    let idx = position
    let totalDocs = this._filteredDocs.length

    while (idx <= totalDocs) {
      this._filteredDocs.splice(idx, 0, createCtaBlockItem(idx))

      // Current position + gap + 1 (to compensate counting from 0)
      idx = idx + gap + 1

      // Each injection makes this._filteredDocs array longer, to have correct
      // logic after a lot of injections, we need to take it into account.
      totalDocs += 1

      // Debugging.
      // console.log(`next: ${idx}; total: ${totalDocs}`)
    }
  }

  needInjectCtaBlocks(): boolean {
    if (!this._showCtaBlock) {
      return false
    }
    if (auth.isFreePlan() && this.filteredDocs.length > 0) {
      return true
    }
    return false
  }

  /*
   * Filter books by `search` string.
   */
  async filter(search: string): Promise<void> {
    this._searcher.searchTerm = search
    await this._updateFilteredDocs()
  }

  /**
   * Get the current search query.
   */
  get searchTerm(): string | null {
    return this._searcher.searchTerm
  }

  /*
   * Sort books by `sort` field.
   */
  sort(sort: DocSortOption): void {
    if (this._sort !== sort) {
      this._sort = sort
      this._updateFilteredDocs()
    }
  }

  /**
   * Set the `_sort` property without updating the order of the docs.
   */
  setSort(sort: DocSortOption): void {
    this._sort = sort
  }

  /**
   * Set tag to filter by.
   */
  setTag(tag: string): void {
    if (this._tag !== tag) {
      this._tag = tag
      this._updateFilteredDocs()
    }
  }

  /**
   * Get current tag filter.
   */
  get tag(): string {
    return this._tag
  }

  /**
   * Set list to filter by.
   */
  setList(list: string): void {
    if (this._list !== list) {
      this._list = list
      this._updateFilteredDocs()
    }
  }

  /**
   * Set a Custom List to filter by.
   */
  setCustomList(url_slug: string): void {
    if (this._custom_list !== url_slug) {
      this._custom_list = url_slug
      this._updateFilteredDocs()
    }
  }

  /**
   * Filter by is favorite.
   */
  setIsFavorite(is_favorite: boolean | null): void {
    if (this._is_favorite !== is_favorite) {
      this._is_favorite = is_favorite
      this._updateFilteredDocs()
    }
  }

  /**
   * Filter books to only show books downloaded to persistent storage.
   *
   * Downloads to persistent storage are implemented in native apps only.
   * On web, this filter results in an empty book list.
   */
  async filterByDownloads(): Promise<void> {
    this._filteredDocs = await getDownloadedBooks(this.docs)
  }

  filterByFree(): void {
    this._filteredDocs = this.docs.filter((doc) => doc.is_free)
  }

  /**
   * Get current list filter.
   */
  get list(): string {
    return this._list
  }

  /**
   * Get current Custom List filter.
   */
  get customList(): string {
    return this._custom_list
  }

  /**
   * All tags to display in the categories filter.
   */
  get tags(): string[] {
    return this._tags
  }

  /**
   * A list of tags for the specified `book`.
   */
  docTags(doc: DocListItem): string[] {
    return doc.tags.concat(doc.hidden_tags).sort()
  }

  /**
   * First tag for the doc.
   */
  firstTag(doc: DocListItem): string {
    return doc.tags.length ? doc.tags[0] : ''
  }

  /**
   * First tag for the doc, short version for mobile.
   *
   * We only keep the first part of the tag before the slash.
   */
  firstTagShort(doc: DocListItem): string {
    const firstTag = this.firstTag(doc)
    const shortTag = firstTag.split('/')[0]
    return shortTag ? shortTag : firstTag
  }

  /**
   * Returns 'true' if tag is visible.
   *
   * We have some 'hidden' tags to improve the look of the books page,
   * some books have many tags and we aim for two rows of tags per book.
   *
   * Hidden tags still particpate in the filter by tag process.
   * And when filtered by tag, it becomes visible even if it is in
   * the hidden book tags list.
   *
   * @returns {undefined}
   */
  isTagVisible(doc: DocListItem, tag: string): boolean {
    if (this._tag) {
      // When search by tag is active, show visible tags and the
      // tag we search for, if it is in book hidden tags.
      // This way the user won't wonder too much why the book is shown
      // during the tag search, but the tag is not on the book card.
      return doc.tags.includes(tag) || doc.hidden_tags.includes(this._tag)
    }
    return doc.tags.includes(tag)
  }

  /**
   * Returns 'true' if the docSort component should be visible.
   */
  isDocSortVisible(): boolean {
    return !this.isLimited && this._sort !== DocSortOption.none
  }

  /**
   * Move book to another list.
   */
  async moveTo(doc: DocListItem, list: string): Promise<void> {
    const result = await backend.moveBookToList(doc, list)
    doc.list = result.list
    this._updateFilteredDocs()
  }

  /**
   * Update the Custom Lists of the given `doc`.
   */
  async updateDocCustomLists(
    doc: DocListItem,
    newLists: CustomListListItem[],
  ): Promise<void> {
    doc.custom_lists = newLists
    await this._updateFilteredDocs()
  }

  listingClass(doc: DocListItem): string {
    if (auth.isPaidPlan()) {
      return ''
    }
    if (!doc.is_free) {
      return 'eoc'
    }
    return ''
  }

  public get pageTitle(): string {
    return this._listingPage.pageTitle
  }

  public get filterTitle(): string {
    return this._listingPage.pageTitle
  }

  public addListingPage(listingPage: ListingPage): void {
    this._listingPage = listingPage
  }

  /**
   * Get header state for listing pages
   */
  get isHeaderCollapsed(): boolean {
    return this._listingPage._isHeaderCollapsed
  }

  /**
   * Set header state from scroll position
   */
  set isHeaderCollapsed(value: boolean) {
    this._listingPage._isHeaderCollapsed = value
  }
}

export function getHighlightPublicLink(
  vm: ComponentPublicInstance,
  annotation: Annotation,
  content: AnnotationContent | ContentView,
): string {
  const location = vm.$router.resolve({
    name: 'public_highlight',
    params: {
      book_url_slug: content.doc.url_slug,
      highlight_id: annotation.id,
    },
  })
  let public_link = location.href
  const origin = window.location.origin

  // It doesn't work with localhost URLs, so we only handle dev and prod.
  if (origin.includes('dev.shortform.com')) {
    public_link = 'https://dev.shortform.com' + public_link
  } else {
    public_link = 'https://www.shortform.com' + public_link
  }
  return public_link
}

/* Returns the given url with an extra '?x' at the end.
 *
 * This is being used to make browsers not use cached versions of
 * images that don't have proper CORS headers in their responses.
 *
 * `cacheBust`, and all calls to it, can be removed after enough
 * time that most users no longer have such responses cached.
 * See #8374 for details.
 */
export function cacheBust(url: string): string {
  return url + '?x'
}

export function pngBigCoverUrl(coverImage: string): string | undefined {
  if (coverImage.endsWith('png')) {
    return coverImage.replace(new RegExp('.png$'), '@8x.png')
  }

  return coverImage
    .replace('/covers/', '/covers/png/')
    .replace(new RegExp('.jpg$'), '@8x.png')
}

export function filteredDocsByDocType(
  response: Doc[],
  doc_type: DocType,
): ApiResultDocs {
  const docs = response.filter((doc) => doc.doc_type === doc_type)

  return {
    data: docs,
  }
}

function getPrefix(doc: DocListItem): string {
  return doc.doc_type === 'podcast_episode' ? 'podcast/episode' : 'summary'
}

function getParsedDocType(doc: DocListItem): string {
  return doc.doc_type === 'podcast_episode' ? 'podcast episode' : 'book'
}

const getShareDocPublicLink = (doc: DocListItem): string => {
  return `https://www.shortform.com/${getPrefix(doc)}/${doc.public_url_slug}`
}
/**
 * Share the doc to Facebook.
 */
export const docShareFacebook = (doc: DocListItem): void => {
  const text = `Check out this summary of the ${getParsedDocType(doc)}: "${
    doc.title
  }"`
  // https://developers.facebook.com/docs/sharing/reference/share-dialog
  FB.ui({
    method: 'share',
    display: 'popup',
    href: getShareDocPublicLink(doc),
    quote: text,
  })
}

/**
 * Share the book to Twitter.
 */
export const docShareTwitter = (doc: DocListItem): void => {
  const url = encodeURIComponent(getShareDocPublicLink(doc))
  const text = `Check out this summary of the ${getParsedDocType(doc)}: "${
    doc.title
  }"`
  const urlText = encodeURIComponent(text)

  window.open(
    `https://twitter.com/intent/tweet?url=${url}&text=${urlText}&via=_shortform`,
  )
}
