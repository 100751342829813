
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue'
import HeaderRoot from '@/app/components/header/Root.vue'

import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { ArticlesView } from '@/models/article'
import CategoriesSelection from '@/app/components/listing/categories/CategoriesSelection.vue'
import InfiniteScrollArticlesBlock from '@/app/components/listing/InfiniteScrollArticlesBlock.vue'
import LoadingError from '@/components/ui/LoadingError.vue'
import vLoading from 'vue-wait/src/components/v-wait.vue'
import { DocsTagsView } from '@/models/docs.tags'
import {
  ArticlesPageType,
  createArticlesListingPage,
} from '@/models/docs.listing'

@Options({
  components: {
    Layout,
    CategoriesSelection,
    HeaderRoot,
    InfiniteScrollArticlesBlock,
    LoadingError,
    'v-wait': vLoading,
  },
  name: 'Articles',
})
export default class ArticlesV4 extends Vue {
  articleView: ArticlesView | null = null
  tagsView: DocsTagsView | null = null

  async beforeMount(): Promise<void> {
    await this.loadArticles()
  }

  async loadArticles(): Promise<void> {
    wait.start(this, 'Loading Articles')
    try {
      const response = await backend.getArticles()

      const listingPage = createArticlesListingPage(ArticlesPageType.all)
      this.articleView = await ArticlesView.createView(
        response,
        listingPage,
        true,
      )

      this.tagsView = new DocsTagsView(response.tags, 'articles')
      wait.end(this, 'Loading Articles')
    } catch (error: any) {
      wait.start(this, 'Error Loading Articles')
      Sentry.captureException(error)
    }
  }
}
