import { assertNotNull } from '@/helpers/typing'
import { DocsView, DocSortOption } from './doc'
import { ArticlesView } from './article'
import { BooksView } from './book'
import { CollectionsView } from './collection'
import { PodcastEpisodesView } from './podcasts.episodes'
import {
  ApiResultDocs,
  DocType,
  CollectionListItem,
  ListItem,
  ListItemType,
  CustomListListItem,
} from './interfaces'

import { backend } from '@/services/backend'
import { loadBookListFromNativeStorage } from '@/services/native.storage'
import { offline } from '@/services/offline'
import { capitalizeTagFirstLetter, truncateSearchTerm } from '@/helpers/text'

/**
 * A minimal interface for the DocsView-like data classes
 * that can be displayed with a ListView.
 */
export interface DocsListView {
  docTypeName(): ListItemType
  get filteredDocs(): ListItem[]
  get dataLength(): number
  setShowLimit(limit: number): void
  isDocSortVisible(): boolean
}

/**
 * Listing view.
 *
 * Wrapper around the DocsView that provides additional settings and
 * logic for the ListPage component.
 */
export class ListView {
  private _title: string
  private _docs: DocsListView
  private _parentPage?: ListItem
  private _isHeaderCollapsed: boolean = false

  public constructor(title: string, docs: DocsListView, parentPage?: ListItem) {
    this._title = title
    this._docs = docs
    this._parentPage = parentPage
  }

  public pageTitle(): string {
    return this._title
  }

  public filterTitle(): string {
    return this._title
  }

  public docTypeName(): ListItemType {
    return this.docs().docTypeName()
  }

  public sectionCssClass(): string {
    return docTypeLabel[this.docTypeName()].cssClass
  }

  public notFoundMessage(): string {
    const docTypesLabel = docTypeLabel[this.docTypeName()].plural
    return `No ${docTypesLabel} found.`
  }

  public docs(): DocsListView {
    return assertNotNull(this._docs)
  }

  public get dataLength(): number {
    return this.docs().dataLength
  }

  public hasDocs(): boolean {
    if (this._docs && this._docs.dataLength > 0) {
      return true
    }
    return false
  }

  public get parentPage(): ListItem | undefined {
    return this._parentPage
  }

  public get descriptionView(): ListDescriptionView | undefined {
    if (this._parentPage instanceof ListDescriptionView) {
      return this._parentPage as ListDescriptionView
    }
  }

  /**
   * Get header state for listing pages
   */
  get isHeaderCollapsed(): boolean {
    return this._isHeaderCollapsed
  }

  /**
   * Set header state from scroll position
   */
  set isHeaderCollapsed(value: boolean) {
    this._isHeaderCollapsed = value
  }

  setShowLimit(limit: number): void {
    this.docs().setShowLimit(limit)
  }
}

/**
 * Description view for the list page.
 */
export class ListDescriptionView implements ListItem {
  id: string
  title: string
  url_slug: string
  created: string
  doc_type: ListItemType
  blurb?: string
  cover_image?: string

  showFullText: boolean

  docsNumber: string
  author: string | undefined
  originalUrl: string | undefined

  constructor(
    docGroup: ListItem,
    docsNumber: string,
    author?: string | undefined,
    originalUrl?: string | undefined,
  ) {
    this.id = docGroup.id
    this.title = docGroup.title
    this.url_slug = docGroup.url_slug
    this.created = docGroup.created
    this.doc_type = docGroup.doc_type
    this.blurb = docGroup.blurb
    this.cover_image = docGroup.cover_image

    this.showFullText = !this.hasLongText
    this.docsNumber = `${docsNumber} books`
    this.author = author
    this.originalUrl = originalUrl
  }

  get fullText(): string {
    return this.blurb as string
  }

  get imageUrl(): string {
    return this.cover_image as string
  }

  get hasLongText(): boolean {
    return this.fullText.length > 500
  }

  get shortText(): string {
    return this.fullText.substring(0, 500) + '...'
  }

  get displayedText(): string {
    return this.showFullText ? this.fullText : this.shortText
  }

  toggleShowFullText(): void {
    this.showFullText = !this.showFullText
  }
}

type DocTypeLabelType = {
  [key in ListItemType]: {
    singular: string
    plural: string
    cssClass: string
  }
}

const docTypeLabel: DocTypeLabelType = {
  article: {
    singular: 'article',
    plural: 'articles',
    cssClass: 'docs-listing--articles',
  },
  book: {
    singular: 'book',
    plural: 'books',
    cssClass: 'docs-listing--books',
  },
  podcast_episode: {
    singular: 'podcast episode',
    plural: 'podcast episodes',
    cssClass: 'docs-listing--podcast-episodes',
  },
  podcast: {
    singular: 'podcast',
    plural: 'podcasts',
    cssClass: 'docs-listing--podcast',
  },
  collection: {
    singular: 'collection',
    plural: 'collections',
    cssClass: 'docs-listing--collections',
  },
  custom_list: {
    singular: 'my list',
    plural: 'my lists',
    cssClass: 'docs-listing--custom-lists',
  },
}

/**
 * Listing page route names for books.
 *
 * How to add a new listing page:
 * - Add the route name to this enum.
 * - Add Listing page configuration to the ``ListingPages`` const.
 * - Add mapping between route name and user-friendly title to the
 *   ``pageTypeToTitleMap`` object.
 */
enum BookPageType {
  all = 'all',
  popular = 'popular',
  new = 'new',
  favorites = 'favorites',
  reading = 'reading',
  finished = 'finished',
  downloads = 'downloads',
  free = 'free',
  recommended = 'recommended',
  category = 'category',
  search = 'search',
}

const userBookPages = [
  BookPageType.favorites,
  BookPageType.downloads,
  BookPageType.reading,
  BookPageType.finished,
]

/**
 * Load books from the backend and create DocsView.
 */
async function loadBooks(tag?: string): Promise<DocsView> {
  if (offline.isOffline) {
    await loadBookListFromNativeStorage()
  }

  const response = await backend.getBooksWithRecommendations()
  const view = await BooksView.createView(
    response,
    undefined,
    undefined, // TODO: remove listingPage,
    true,
  )
  if (tag) {
    view.setTag(tag)
  }
  return view
}

type DocsViewAndCollection = {
  docsView: DocsView
  collection: CollectionListItem
}

type DocsViewAndCustomList = {
  docsView: DocsView
  list: CustomListListItem
}

/**
 * Load books from the backend and create DocsView.
 */
async function loadCollectionBooks(
  collectionUrlSlug: string,
): Promise<DocsViewAndCollection> {
  if (offline.isOffline) {
    await loadBookListFromNativeStorage()
  }

  const response = await backend.getCollectionBooks(collectionUrlSlug)
  return {
    docsView: await BooksView.createView(
      response,
      undefined,
      undefined, // TODO: remove listingPage,
      true,
    ),
    collection: response.collection,
  }
}

/**
 * Load books from the backend and create DocsView.
 */
async function loadCustomListDocs(
  listUrlSlug: string,
): Promise<DocsViewAndCustomList> {
  const response = await backend.getCustomListDocs(listUrlSlug)
  return {
    docsView: await BooksView.createView(
      response,
      DocSortOption.none,
      undefined, // TODO: remove listingPage,
      true,
    ),
    list: response.list,
  }
}

/**
 * Create a list view for books based on the page type, get data from the home API.
 */
async function createUserBooksListView(
  pageType: BookPageType,
): Promise<ListView> {
  const response = await backend.getUserBooksWithRecommendations()

  const docsView = await BooksView.createView(
    response,
    undefined,
    undefined, // TODO: remove listingPage,
    true,
  )

  switch (pageType) {
    case BookPageType.favorites:
      docsView.enableActionsButton()
      docsView.setIsFavorite(true)
      return new ListView('Favorites', docsView)

    case BookPageType.reading:
      docsView.enableContinueReading()
      docsView.enableActionsButton()
      docsView.setList('reading')
      docsView.sort(DocSortOption.accessed)
      return new ListView('Reading Now', docsView)

    case BookPageType.finished:
      docsView.enableActionsButton()
      docsView.setList('finished')
      return new ListView('Finished', docsView)

    case BookPageType.downloads:
      await docsView.filterByDownloads()
      return new ListView('Downloads', docsView)
  }
  throw new Error('Unexpected page type: ' + pageType)
}

/**
 * Create a list view for books based on the page type, get data from the books API.
 */
async function createBooksListView(pageType: BookPageType): Promise<ListView> {
  const docsView = await loadBooks()

  switch (pageType) {
    case BookPageType.all:
      return new ListView('All Books', docsView)

    case BookPageType.new:
      docsView.sort(DocSortOption.first_published_at)
      docsView.setDocsLimit(20)
      return new ListView('New Releases', docsView)

    case BookPageType.popular:
      docsView.sort(DocSortOption.popular)
      docsView.setDocsLimit(20)
      return new ListView('Popular', docsView)

    case BookPageType.free:
      docsView.filterByFree()
      return new ListView('Free', docsView)

    case BookPageType.recommended:
      docsView.sort(DocSortOption.recommended)
      docsView.setDocsLimit(20)
      return new ListView('Recommended', docsView)
  }
  throw new Error('Unexpected page type: ' + pageType)
}

async function loadCollections(): Promise<CollectionsView> {
  const collectionsData = await backend.getBookCollections()
  return new CollectionsView(collectionsData)
}

enum ArticlePageType {
  all = 'all',
  favorites = 'favorites',
  viewed = 'viewed',
}

/**
 * Load articles from the backend and create DocsView.
 */
async function loadArticles(tag?: string): Promise<DocsView> {
  const response = await backend.getArticles()
  const view = await ArticlesView.createView(response, undefined, true)
  if (tag) {
    view.setTag(tag)
  }
  return view
}

/**
 * Create a list view for articles based on the page type.
 */
async function createArticlesListView(
  pageType: ArticlePageType,
): Promise<ListView> {
  const docsView = await loadArticles()

  switch (pageType) {
    case ArticlePageType.all:
      return new ListView('All Articles', docsView)

    case ArticlePageType.favorites:
      docsView.setIsFavorite(true)
      return new ListView('Favorites', docsView)

    case ArticlePageType.viewed:
      docsView.enableContinueReading()
      docsView.enableActionsButton()
      docsView.setList('viewed')
      docsView.sort(DocSortOption.accessed)
      return new ListView('Viewed', docsView)
  }
  throw new Error('Unexpected page type: ' + pageType)
}

/**
 * Create a list view with search results.
 */
export async function createFollowedPodcastEpisodesView(): Promise<ListView> {
  const showPodcastAsSubtitle = true
  const response = await backend.getFollowedPodcastEpisodes()
  const docsView = new PodcastEpisodesView(
    response,
    undefined, // TODO: remove listingPage,
    false,
    showPodcastAsSubtitle,
    DocSortOption.none,
  )
  docsView.setDocsLimit(100)
  return new ListView('Latest Episodes', docsView)
}

/**
 * Load search results for podcast episodes.
 */
async function searchPodcastEpisodes(searchTerm: string): Promise<DocsView> {
  const response = await searchDocsLimited('podcast_episode', searchTerm)
  return PodcastEpisodesView.createView(
    response,
    undefined,
    false,
    false,
    DocSortOption.none,
  )
}

/**
 * A helper function to load search results for books.
 */
async function searchBooks(searchTerm: string): Promise<DocsView> {
  const response = await searchDocsLimited('book', searchTerm)
  return BooksView.createView(
    response,
    DocSortOption.none,
    undefined, // Temp this.listingPage,
    true,
  )
}

export async function searchArticles(searchTerm: string): Promise<DocsView> {
  const response = await searchDocsLimited('article', searchTerm)
  return ArticlesView.createView(
    response,
    undefined, // Temp this.listingPage,
    true,
    DocSortOption.none,
  )
}

/**
 * A helper function to load search results.
 */
async function searchDocsLimited(
  docType: DocType,
  searchTerm: string,
): Promise<ApiResultDocs> {
  // We limit the search results to 100 documents.
  // In the future we need to increase this or/and add paging here.
  const limit = 100
  return await backend.getDocSearch(docType, searchTerm, limit)
}

type RouteParams = {
  name?: string
  category?: string
  term?: string
  url_slug?: string
  collection?: string
}

/**
 * Create a listing view based on the route name and parameters.
 */
export async function createListViewByRoute(
  routeName: string,
  params: RouteParams,
): Promise<ListView> {
  if (routeName === 'articleCategory') {
    const tag = decodeURI(params.category as string)
    const title = capitalizeTagFirstLetter(tag)
    const docsView = await loadArticles(tag)
    return new ListView(title, docsView)
  }

  if (routeName === 'bookCategory') {
    const tag = decodeURI(params.category as string)
    const title = capitalizeTagFirstLetter(tag)
    const docsView = await loadBooks(tag)
    return new ListView(title, docsView)
  }

  if (routeName === 'bookSpecialListingPage') {
    const pageType = params.name as BookPageType
    if (userBookPages.includes(pageType)) {
      // Load data from the home API
      return createUserBooksListView(pageType)
    } else {
      // Load data from the books API
      return createBooksListView(pageType)
    }
  }

  if (routeName === 'articleSpecialListingPage') {
    const pageType = params.name as ArticlePageType
    return createArticlesListView(pageType)
  }

  if (routeName === 'bookSearchPage') {
    const term = params.term as string
    const title = `Search results for "${truncateSearchTerm(term)}"`
    const docsView = await searchBooks(term)
    return new ListView(title, docsView)
  }

  if (routeName === 'articleSearchPage') {
    const term = params.term as string
    const title = `Search results for "${truncateSearchTerm(term)}"`
    const docsView = await searchArticles(term)
    return new ListView(title, docsView)
  }

  if (routeName === 'podcast_episodes.search') {
    const term = params.term as string
    const title = `Search results for "${truncateSearchTerm(term)}"`
    const docsView = await searchPodcastEpisodes(term)
    return new ListView(title, docsView)
  }

  if (routeName === 'podcast_episodes.followed') {
    return await createFollowedPodcastEpisodesView()
  }

  if (routeName === 'bookCollection') {
    const collectionUrlSlug = params.collection as string
    const { docsView, collection } = await loadCollectionBooks(
      collectionUrlSlug,
    )
    const descriptionView = new ListDescriptionView(
      collection,
      collection.doc_count,
    )
    return new ListView(descriptionView.title, docsView, descriptionView)
  }

  if (routeName === 'collections') {
    const title = 'All Collections'
    const docsView = await loadCollections()
    return new ListView(title, docsView)
  }

  if (routeName === 'customList') {
    const url_slug = assertNotNull(params.url_slug)

    const { docsView, list } = await loadCustomListDocs(url_slug)

    docsView.setCustomList(url_slug)
    docsView.enableActionsButton()

    return new ListView(list.title, docsView, list)
  }

  throw new Error('Unexpected route name: ' + routeName)
}
