import { AuthData } from '@/models/interfaces.auth'
import { bookTutorialSetAutoShow } from '@/models/doc.tutorial'

import { saveMarketingUrl, trackLogin, trackSignup } from '@/analytics/logic'

import { auth } from '@/services/auth'
import {
  isLongOnboardingVariant,
  longOnboarding15290ExperimentVariant,
} from '@/services/ab'

/**
 * Post-authentication logic.
 *
 * Called from social login auth where we are either logging in or
 * signing up.
 * Depending on the `auth_type` we run the after-login or after-signup
 * logic.
 */
export async function afterAuth(
  authData: AuthData,
): Promise<Record<string, unknown> | null> {
  if (authData.auth_type === 'login') {
    return afterLogin(authData)
  }
  return afterSignup(authData)
}

/**
 * Logic to run after user login.
 *
 * We save the user authentication data to the local storage and:
 *
 * - Save the marketing URL (if it is present) for discount detection
 * - Invoke Mixpanel.login logic
 */
export async function afterLogin(
  authData: AuthData,
): Promise<Record<string, unknown>> {
  auth.saveAuth(authData.token, authData.user)

  await saveMarketingUrl()
  await trackLogin(authData.user)

  return { name: 'home' }
}

/*
 * Post registration logic.
 *
 * This method is unified to be used both by regular and social logins.
 *
 * Here we make the following steps:
 * - Save the auth data to the local storage.
 * - Enable book tutorial auto-show on book page visit.
 * - Start user tracking with external services.
 * - Depending on the onboarding_questionnaire_14335 experiment variant:
 *    - Navigate to questionnaire or billing page
 */
export async function afterSignup(
  authData: AuthData,
): Promise<Record<string, unknown> | null> {
  auth.saveAuth(authData.token, authData.user)
  bookTutorialSetAutoShow(true)

  await trackSignup(authData.user)

  const variant = longOnboarding15290ExperimentVariant()

  if (isLongOnboardingVariant(variant)) {
    // Long onboarding (includes a custom signup page)
    return null
  }

  return { name: 'questionnaire' }
}
