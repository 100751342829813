
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue'
import HeaderRoot from '@/app/components/header/Root.vue'
import CategoriesSelection from '@/app/components/listing/categories/CategoriesSelection.vue'
import LatestEpisodesBlock from '@/app/components/listing/LatestEpisodesBlock.vue'
import InfiniteScrollPodcastsBlock from '@/app/components/listing/InfiniteScrollPodcastsBlock.vue'
import EarlyAccessBannerPodcast from '@/app/components/doc/EarlyAccessBannerPodcast.vue'
import LoadingError from '@/components/ui/LoadingError.vue'
import vLoading from 'vue-wait/src/components/v-wait.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

import { RouteLocationNormalized } from 'vue-router'
import { PodcastsView } from '@/models/podcasts'
import { createFollowedPodcastEpisodesView, ListView } from '@/models/docs.list'

@Options({
  components: {
    Layout,
    HeaderRoot,
    CategoriesSelection,
    LoadingSpinner,
    'v-wait': vLoading,
    LoadingError,
    InfiniteScrollPodcastsBlock,
    EarlyAccessBannerPodcast,
    LatestEpisodesBlock,
  },
  name: 'Podcasts',
})
export default class Podcasts extends Vue {
  podcastsView: PodcastsView | null = null
  followedEpisodesView: ListView | null = null

  async beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): Promise<void> {
    if (from.name === 'book.page') {
      to.query = { from: from.name }
    }
  }

  async beforeMount(): Promise<void> {
    wait.start(this, 'Loading Podcasts')

    try {
      await Promise.all([this.loadPodcasts(), this.loadFollowedEpisodes()])
      wait.end(this, 'Loading Podcasts')
    } catch (error) {
      wait.start(this, 'Error Loading Podcasts')
      Sentry.captureException(error)
    }
  }

  async loadPodcasts(): Promise<void> {
    const response = await backend.getPodcasts()
    this.podcastsView = new PodcastsView(response)
  }

  async loadFollowedEpisodes(): Promise<void> {
    // This function has a try/catch block so that it doesn't
    // prevent the page from loading if there's an error
    try {
      this.followedEpisodesView = await createFollowedPodcastEpisodesView()
    } catch (error: any) {
      Sentry.captureException(error)
    }
  }
}
