
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { StripeElements, StripeElement } from 'vue-stripe-js'

import { STRIPE_PUBLIC_KEY, IS_UNIT_TESTING } from '@/init/settings'
import { ValidationState } from '@/helpers/form'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

import { BillingView } from '@/models/billing'
import FormError from '@/components/ui/FormError.vue'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import { getEmail } from '@/services/auth'

@Options({
  components: {
    StripeElements,
    StripeElement,
    FormError,
    'v-wait': vLoading,
    LoadingSpinner,
  },
})
export default class StripeCard extends Vue {
  @Prop() private billing!: BillingView
  @Prop({ default: true }) private showButton!: boolean
  @Prop({ default: false }) private enablePaymentElement!: boolean

  validation: ValidationState = new ValidationState()

  // True once card data has been changed
  private changed: boolean = false
  // True once Stripe reports the the card is valid (but we din't save it yet).
  private complete: boolean = false
  // True while we are saving card data.
  private saving: boolean = false
  // True once we successfully saved card, triggers the success alert.
  private saved: boolean = false

  private setupIntent: Record<string, any> = {}
  // True once we confirmed the setupIntent
  private setupIntentConfirmed: boolean = false

  // https://stripe.com/docs/js/initializing#init_stripe_js-options
  private instanceOptions: any = {}
  // https://stripe.com/docs/stripe.js#element-options
  private cardOptions: any = {
    style: {
      base: { fontSize: '16px', color: '#32325d' },
    },
  }

  // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  private elementsOptions: any = {
    // We can pass additional options to customize card element style,
    // see https://stripe.com/docs/stripe.js#element-options for details.
    // Note: for some reason 'appearance' does not work here, but
    // 'style' in cardOptions does.
    // appearance: {
    //   variables: { fontSizeBase: '16px', colorPrimary: '#32325d' },
    // },
  }

  private expressCheckoutOptions: any = {
    buttonType: {
      applePay: 'subscribe',
      googlePay: 'subscribe',
    },
    buttonTheme: {
      applePay: 'white',
      googlePay: 'white',
    },
    paymentMethods: {
      applePay: 'always',
      googlePay: 'always',
    },
    paymentMethodOrder: ['apple_pay', 'google_pay', 'link'],
  }

  private elementsOptionsAppearance = {
    theme: 'stripe',
    variables: {
      colorPrimaryText: '#141C31',
      colorBackground: '#F8F8F8',
      fontFamily: 'Rotunda, sans-serif',
      fontSizeBase: '16px',
    },
    rules: {
      '.Input': {
        border: '1px solid #D5D8E0',
        lineHeight: '20px',
      },
      '.Label': {
        fontWeight: 500,
        lineHeight: '18px',
      },
      '.Tab': {
        border: '1px solid #D4D0D0',
      },
      '.Tab--selected': {
        border: '1px solid #363B48',
      },
    },
  }

  async beforeMount(): Promise<void> {
    if (this.enablePaymentElement) {
      this.elementsOptions.mode = 'setup'
      this.elementsOptions.currency = 'usd'
      // To style the Payment Element we use Stripe Appearance API
      // https://docs.stripe.com/elements/appearance-api
      this.elementsOptions.appearance = this.elementsOptionsAppearance
      this.cardOptions.defaultValues = {
        billingDetails: {
          email: getEmail(),
        },
      }
      this.setupIntent = await backend.getSetupIntent()
    }
  }

  async unmounted(): Promise<void> {
    // If the user didn't add payment data, cancel the setup intent
    if (this.enablePaymentElement && !this.setupIntentConfirmed) {
      await backend.cancelSetupIntent(this.setupIntent)
    }
  }

  changedHandler($event: any): void {
    this.changed = true
    this.complete = $event.complete
  }

  needsSaving(): boolean {
    return this.changed
  }

  async paymentElementConfirmStripe(): Promise<any> {
    // @ts-ignore
    const confirmSetupFn = this.$refs.elms.instance.confirmSetup
    const params = {
      // @ts-ignore
      elements: this.$refs.elms.elements,
      clientSecret: this.setupIntent.client_secret,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://www.shortform.com/app/billing',
      },
    }
    // @ts-ignore
    await this.$refs.elms.elements.submit()
    const { error } = await confirmSetupFn(params)
    if (error) {
      return { error: error }
    }

    this.setupIntentConfirmed = true
    return { success: true }
  }

  async cardConfirmStripe(): Promise<any> {
    // @ts-ignore
    const cardElement = this.$refs.card.stripeElement
    // @ts-ignore
    let createTokenFn = this.$refs.elms.instance.createToken
    if (IS_UNIT_TESTING) {
      // Temporary: mock createToken here (TODO: mock it in tests code)
      createTokenFn = (window as any).Stripe_createToken
    }
    // The createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.
    const data = await createTokenFn(cardElement)
    return data
  }

  /**
   * Express Checkout Element click handler.
   *
   * We need to have the click handler for the element,
   * because we need to immediately call `event.resolve()` with `client_secret`.
   * It also adds an event listener for `confirm` event.
   */
  async expressCheckoutClick(event: Event): Promise<any> {
    // @ts-ignore
    this.$refs.express.stripeElement.addEventListener(
      'confirm',
      this.expressCheckoutConfirm,
    )
    // @ts-ignore
    event.resolve()
  }

  /**
   * Express Checkout Element confirm handler.
   *
   * This method is called after the user goes through the payment option's
   * process: for example, chooses card, clicks confirm.
   */
  async expressCheckoutConfirm(): Promise<any> {
    // @ts-ignore
    const { error: submitError } = await this.$refs.elms.elements.submit()
    if (submitError) {
      this.handleStripeError(submitError)
      return
    }

    // Create a ConfirmationToken using the details collected by the Express Checkout Element
    // @ts-ignore
    const { error, confirmationToken } =
      // @ts-ignore
      await this.$refs.elms.instance.createConfirmationToken({
        // @ts-ignore
        elements: this.$refs.elms.elements,
        params: {
          return_url: 'https://www.shortform.com/app/billing',
        },
      })

    if (error) {
      // From Stripe documentation:
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      // https://docs.stripe.com/elements/express-checkout-element/accept-a-payment?client=html#create-ct
      this.handleStripeError(error)
      console.log(error)
      return false
    }

    // @ts-ignore
    const confirmSetupFn = this.$refs.elms.instance.confirmSetup
    const params = {
      clientSecret: this.setupIntent.client_secret,
      redirect: 'if_required',
      confirmParams: {
        confirmation_token: confirmationToken.id,
        return_url: 'https://www.shortform.com/app/billing',
      },
    }
    // @ts-ignore
    const { confirmationError } = await confirmSetupFn(params)
    if (confirmationError) {
      this.handleStripeError(confirmationError)
      return false
    }
    this.setupIntentConfirmed = true
    const billing = await backend.savePaymentMethod({
      id: this.setupIntent.id,
    })
    this.billing.card_data = billing.card_data
    this._setSaved('')

    // There is a new billing card data returned by stripe,
    // if we saved it successfully, update this.billing,
    // so the new card is displayed.
    this.$emit('saved')

    return true
  }

  /**
   * Save card, returns true on success
   */
  async save(): Promise<boolean> {
    this.saving = true

    try {
      if (this.enablePaymentElement) {
        const data = await this.paymentElementConfirmStripe()
        if (data.error) {
          this.handleStripeError(data.error)
          return false
        }
        const billing = await backend.savePaymentMethod({
          id: this.setupIntent.id,
        })
        this.billing.card_data = billing.card_data
      } else {
        const data = await this.cardConfirmStripe()
        if (data.error) {
          this.handleStripeError(data.error)
          return false
        }
        await backend.saveCard(data.token)
        this.billing.card_data = data.token['card']
      }
      this._setSaved('')

      // There is a new billing card data returned by stripe,
      // if we saved it successfully, update this.billing,
      // so the new card is displayed.
      this.$emit('saved')
      return true
    } catch (error) {
      this._setSaved(error)
    }
    return false
  }

  /**
   * Update state flags depending on the error value.
   */
  _setSaved(error: any): void {
    if (error) {
      this.saving = false
      this.saved = false
      this.changed = true
      this.validation.showErrors(error)
    } else {
      this.saving = false
      this.saved = true
      this.changed = false
      this.validation.reset()
    }
  }

  handleStripeError(error: any): void {
    // Something went wrong on Stripe side.
    const expected_errors = [
      'incomplete_zip',
      'incomplete_cvc',
      'incomplete_expiry',
      'incomplete_number',
      'invalid_number',
      'invalid_expiry_month_past',
    ]
    if (error.code && expected_errors.indexOf(error.code) === -1) {
      // Unexpected error, send to Sentry.
      Sentry.withScope((scope) => {
        scope.setExtra('stripeData', error)
        Sentry.captureMessage(error.message)
      })
    }
    this._setSaved({
      response: {
        status: 400,
        data: { errors: { card: [error.message] } },
      },
    })
  }

  get stripeKey(): string {
    return STRIPE_PUBLIC_KEY
  }

  get savedAlert(): number | boolean {
    if (this.saved) {
      // 3 seconds to hide the alert
      return 3
    }
    return false
  }

  get elementClass(): string {
    return this.enablePaymentElement ? 'stripe-payment-element' : 'stripe-card'
  }
}
