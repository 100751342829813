export const ANDROID_APP_ID = 'market://details?id=com.shortform.app'
export const IOS_APP_ID = '1489472007'

export const MIXPANEL_TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN
export const MIXPANEL_DEBUG = process.env.VUE_APP_MIXPANEL_DEBUG === 'true'

export const ADJUST_ANDROID_TOKEN = process.env.VUE_APP_ADJUST_ANDROID_TOKEN
export const ADJUST_IOS_TOKEN = process.env.VUE_APP_ADJUST_IOS_TOKEN

export const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY

export const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID

export const TRUSTPILOT_KEY = process.env.VUE_APP_TRUSTPILOT_KEY

export const APP_ENV = process.env.VUE_APP_ENV
export const APP_TITLE = process.env.VUE_APP_TITLE
export const APP_VERSION = process.env.VUE_APP_VERSION

export const IS_LOCAL = process.env.VUE_APP_ENV === 'local'
export const IS_DEVELOP = process.env.VUE_APP_ENV === 'develop'
export const IS_PRODUCTION = process.env.VUE_APP_ENV === 'production'
export const IS_NOT_PRODUCTION = process.env.VUE_APP_ENV !== 'production'

export const RELEASE = process.env.VUE_APP_RELEASE

export const BACKEND_URL = process.env.VUE_APP_BACKEND
export const BACKEND_TIMEOUT = process.env.VUE_APP_BACKEND_TIMEOUT
export const IS_LOCAL_BACKEND =
  process.env.VUE_APP_BACKEND.startsWith('http://localhost') ||
  process.env.VUE_APP_BACKEND.startsWith('http://backend')

export const IS_UNIT_TESTING = !!window['__UNIT_TESTING']
export const IS_PRERENDERING = !!window['__PRERENDER_INJECTED']
export const IS_NOT_PRERENDERING = !IS_PRERENDERING

// Width at which we start showing bottom bar instead of sidebar
export const DESKTOP_WIDTH = 1151
// Width at which we start showing the mobile menu instead of header
export const MOBILE_MENU_WIDTH = 992

// Show and hide threshold to make sidebar/header show/hide less sensitive to
// small scrolls.
export const SCROLL_SHOW_THRESHOLD = 5
export const SCROLL_HIDE_THRESHOLD = -6

// Show sidebar/header on the scroll to the top/bottom of the page on mobile
export const SCROLL_SHOW_THRESHOLD_MOBILE = 40

// Width at which we switch to the mobile layout
export const MOBILE_WIDTH = 576
export const MEDIUM_WIDTH = 768

export const TABLET_WIDTH = 991

export const MAX_HIGHLIGHT_TEXT_LENGTH = 1500
export const MAX_NOTE_LENGTH = 5000

export const FIREBASE_CONFIG = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)

export const GA4_MEASUREMENT_ID = 'G-04X4NL6EFY'

// The "Shortform Production App" Weglot project for production.
// The "Shortform Develop App" Weglot project for all other cases.
export const WEGLOT_API_KEY = IS_PRODUCTION
  ? 'wg_8d282ca407b12632705052d93d9c5e492'
  : 'wg_88b2fdfcc1d03222d5826563a89d6ac85'
// Allowed translation languages for production and for all other cases.
export const WEGLOT_ALLOWED_TRANSLATION_LANGS = IS_PRODUCTION
  ? ['es', 'de', 'pt-br']
  : ['es', 'pt-br']
