
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import RootPageLayout from '@/app/components/RootPageLayout.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import ToggleButton from '@/components/ui/ToggleButton.vue'
import { SettingsView } from '@/models/settings'
import { getEmail } from '@/services/auth'
import InfoDeleteAccount from '@/app/components/settings/InfoDeleteAccount.vue'
@Options({
  components: {
    RootPageLayout,
    LoadingSpinner,
    ToggleButton,
    InfoDeleteAccount,
  },
})
export default class Settings extends Vue {
  private settingsView: SettingsView = new SettingsView()
  private email: string | null = null

  private async updateNotifyNewRelease(value: boolean): Promise<void> {
    await this.settingsView.toggleNotificationSetting(
      'notify_new_release',
      value,
    )
  }

  async beforeMount(): Promise<void> {
    await this.settingsView.init()
    this.email = getEmail()
  }
}
