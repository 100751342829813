
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { BillingView, PlanView } from '@/models/billing'
import { wait } from '@/helpers/vue-wait'
import { ValidationState } from '@/helpers/form'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import StripeCard from '@/app/components/billing/StripeCard.vue'
import StripeCardInfo from '@/app/components/billing/StripeCardInfo.vue'
import BasePopup from '@/components/ui/BasePopup.vue'

import { stripePaymentOptions15213 } from '@/services/ab'

@Options({
  components: {
    BasePopup,
    StripeCard,
    StripeCardInfo,
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
  },
})
export default class SubscribePopup extends Vue {
  @Prop() private billing!: BillingView
  @Prop() private plan!: PlanView

  private visible: boolean = false
  private validation: ValidationState = new ValidationState()
  private enablePaymentElement = false

  async beforeMount(): Promise<void> {
    this.enablePaymentElement = await stripePaymentOptions15213()
  }

  show(): void {
    this.visible = true
    ;(this.$refs.popup as any).show()
  }

  hide(): void {
    this.visible = false
    this.validation.reset()
    const popup = this.$refs.popup as any
    if (popup) {
      // Popup can already disappear due to the component rerendering.
      popup.hide()
    }
  }

  async submitForm(event: Event): Promise<void> {
    event.preventDefault()
    await this.subscribe()
  }

  async subscribe(): Promise<void> {
    try {
      let cardSuccess = true
      wait.start(this, 'Subscribing')
      if (
        this.billing.cardEditMode &&
        (this.$refs.stripeCard as any).needsSaving()
      ) {
        cardSuccess = await (this.$refs.stripeCard as any).save()
      }
      if (cardSuccess) {
        await this.billing.subscribeTo(this.plan)
        this.$emit('subscribed')
        this.validation.reset()
        this.hide()
      }
      wait.end(this, 'Subscribing')
    } catch (error) {
      wait.end(this, 'Subscribing')
      this.validation.showErrors(error)
    }
  }

  get subscribePopupChargesText(): string {
    if (this.plan.priceIntroPeriod === null) {
      return (
        `charge the subscription fee of ${this.plan.priceSubscribed} to your payment ` +
        `method on ${this.plan.basis} basis until you cancel.`
      )
    } else {
      return (
        `charge the subscription fee of ${this.plan.priceIntroPeriod} to your payment ` +
        `method for your first ${this.plan.period}, then ${this.plan.priceSubscribed} ` +
        `for subsequent ${this.plan.period}s on ${this.plan.basis} basis until you cancel.`
      )
    }
  }
}
