
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ListingBlockHeader from '@/app/components/listing/ListingBlockHeader.vue'
import { Mixpanel } from '@/analytics/mixpanel'

@Options({
  components: {
    ListingBlockHeader,
  },
})

/**
 * @param title - The text to display in the header of the listing block.
 * @param viewAllLink - The link to the view all page for the document type.
 * @param numberOfColumns - The number of columns to display in the desktop breakpoint.
 * @param useBigCards - Whether to use big cards or small cards in the mobile breakpoints.
 * @param trackDocView - Whether or not to track clicks on this component's cards to Mixpanel.
 */
export default class ListingBlock extends Vue {
  @Prop() title!: string
  @Prop() viewAllLink?: string
  @Prop() viewAllText?: string
  @Prop({ default: 3 }) numberOfColumns!: number
  @Prop({ default: true }) useBigCards!: boolean
  @Prop({ default: true }) trackDocView!: boolean

  clickHandler(event: Event): void {
    if (!this.trackDocView) {
      return
    }

    try {
      Mixpanel.trackDocView(
        event,
        this.title,
        this.$router.options.history.state.back as string,
      )
    } catch (error) {
      // Unexpected error in the Mixpanel tracking
      console.error(error)
      return
    }
  }

  get innerWrapperVariantClass(): string {
    return this.useBigCards
      ? 'listing-block__inner--big-cards'
      : 'listing-block__inner--small-cards'
  }

  get numberOfColumnsVariable(): string {
    return `--listing-block-number-of-columns: ${this.numberOfColumns};`
  }
}
