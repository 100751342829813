import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6feef240"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "layout__wrapper"
}
const _hoisted_2 = { class: "subscription-box" }
const _hoisted_3 = {
  key: 1,
  class: "layout__wrapper"
}
const _hoisted_4 = { class: "subscription-box" }
const _hoisted_5 = {
  key: 2,
  class: "layout__wrapper container"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 3,
  class: "layout__wrapper container"
}
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderRoot = _resolveComponent("HeaderRoot")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_LoadingError = _resolveComponent("LoadingError")!
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!
  const _component_BillingDataRedesigned = _resolveComponent("BillingDataRedesigned")!
  const _component_BillingData = _resolveComponent("BillingData")!
  const _component_BillingDataPaid = _resolveComponent("BillingDataPaid")!
  const _component_BillingDataPaidApple = _resolveComponent("BillingDataPaidApple")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "billing-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderRoot, { title: "Billing" }),
      (_ctx.isLoadingBilling())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createVNode(_component_loading_spinner)
            ])
          ]))
        : (_ctx.isErrorLoadingBilling())
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("section", _hoisted_4, [
                _createVNode(_component_LoadingError)
              ])
            ]))
          : (_ctx.isAppleWebBilling)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ContentWrapper, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" You are subscribed with Apple in-app purchase subscription. Please use native iOS app or "),
                    _createElementVNode("a", {
                      href: _ctx.billing.paymentProviderSettingsUrl,
                      target: "_blank",
                      class: "provider-settings-link"
                    }, " Apple subscription interface ", 8, _hoisted_6),
                    _createTextVNode(" to manage your subscription. ")
                  ]),
                  _: 1
                })
              ]))
            : (_ctx.isGoogleWebBilling)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_ContentWrapper, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" You are subscribed through Google Play. Please use the native Android app or the "),
                      _createElementVNode("a", {
                        href: _ctx.billing.paymentProviderSettingsUrl,
                        target: "_blank",
                        class: "provider-settings-link"
                      }, " Google Play settings ", 8, _hoisted_8),
                      _createTextVNode(" to manage your subscription. ")
                    ]),
                    _: 1
                  })
                ]))
              : (_ctx.isFree && _ctx.isBillingRedesignVariantC)
                ? (_openBlock(), _createBlock(_component_BillingDataRedesigned, {
                    key: 4,
                    billing: _ctx.billing
                  }, null, 8, ["billing"]))
                : (_ctx.isFree && !_ctx.isBillingRedesignVariantC)
                  ? (_openBlock(), _createBlock(_component_BillingData, {
                      key: 5,
                      billing: _ctx.billing,
                      "first-visit": _ctx.firstVisit
                    }, null, 8, ["billing", "first-visit"]))
                  : (_ctx.isStripePaid)
                    ? (_openBlock(), _createBlock(_component_BillingDataPaid, {
                        key: 6,
                        billing: _ctx.billing
                      }, null, 8, ["billing"]))
                    : (_ctx.isApplePaid)
                      ? (_openBlock(), _createBlock(_component_BillingDataPaidApple, {
                          key: 7,
                          billing: _ctx.billing
                        }, null, 8, ["billing"]))
                      : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}