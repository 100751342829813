import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2b12688"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "alert alert-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StripeElement = _resolveComponent("StripeElement")!
  const _component_StripeElements = _resolveComponent("StripeElements")!
  const _component_FormError = _resolveComponent("FormError")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.elementClass)
    }, [
      _createVNode(_component_StripeElements, {
        ref: "elms",
        "stripe-key": _ctx.stripeKey,
        "instance-options": _ctx.instanceOptions,
        "elements-options": _ctx.elementsOptions
      }, {
        default: _withCtx(({ elements, instance }) => [
          (_ctx.enablePaymentElement)
            ? (_openBlock(), _createBlock(_component_StripeElement, {
                key: 0,
                id: "express-checkout-element",
                ref: "express",
                type: 'expressCheckout',
                elements: elements,
                options: _ctx.expressCheckoutOptions,
                onChange: _ctx.changedHandler,
                onClick: _ctx.expressCheckoutClick
              }, null, 8, ["elements", "options", "onChange", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_StripeElement, {
            ref: "card",
            type: _ctx.enablePaymentElement ? 'payment' : 'card',
            elements: elements,
            options: _ctx.cardOptions,
            onChange: _ctx.changedHandler
          }, null, 8, ["type", "elements", "options", "onChange"])
        ]),
        _: 1
      }, 8, ["stripe-key", "instance-options", "elements-options"])
    ], 2),
    _createVNode(_component_FormError, {
      class: _normalizeClass(_ctx.validation.stateClass('card') === 'is-invalid' ? 'd-block' : ''),
      errors: _ctx.validation.errors,
      field: "card"
    }, null, 8, ["class", "errors"]),
    (_ctx.showButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-primary mt-3",
          disabled: !_ctx.complete || _ctx.saving,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, " Save Card ", 8, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, "Card Saved", 512), [
      [_vShow, _ctx.savedAlert]
    ])
  ], 64))
}