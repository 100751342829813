export class TestimonialRecord {
  public author: string
  public image: string
  public text: string
  public subtitle?: string

  constructor(author: string, image: string, text: string, subtitle?: string) {
    this.author = author
    this.image = image
    this.text = text
    this.subtitle = subtitle
  }
}

export const DATA = {
  home: [
    new TestimonialRecord(
      'Stacey Morris',
      'testimonial-stacey-morris.jpg',
      'You guys really hit it out of the park again with The 48 Laws of Power! I’ve never seen such a great analysis of this book. The exercises are perfect – not overwhelming or meager, but just right. Thanks for making me smarter every day.',
    ),
    new TestimonialRecord(
      'Brad Bergan',
      'testimonial-brad-bergan.jpg',
      "Shortform’s most valuable benefits are understanding a book's key ideas better, the satisfaction of grasping difficult books with speed and ease, and understanding subjects that really interest me.",
    ),
    new TestimonialRecord(
      'Jerry McPhee',
      'testimonial-jerry-mcphee.jpg',
      'I LOVE Shortform as these are the BEST book companions I’ve ever seen...and I’ve looked at lots of similar sites. The 1-pager and then the longer review is so useful. I read Shortform nearly every day.',
    ),
  ],
  homeAB15080: [
    new TestimonialRecord(
      'Sahil Bloom',
      'testimonial-sahil-bloom.webp',
      'If you’re like me and always on the hunt for ways to read more nonfiction books and boost your productivity, I highly recommend checking out Shortform. It’s become my secret weapon for absorbing the most important insights from the world’s top nonfiction books.',
      'Pulitzer Prize Finalist, New York Times Bestseller',
    ),
    new TestimonialRecord(
      'Ali Abdaal',
      'testimonial-ali-abdaal.webp',
      "I've been using Shortform for years, it's by far the best non-fiction book summary service out there. If you like books, learning and doing those things in a time efficient way then you should definitely check out Shortform.",
      'Productivity Youtuber, 500 million views',
    ),
    new TestimonialRecord(
      'Mark Manson',
      'testimonial-mark-manson.webp',
      'I’ve found Shortform to be a fantastic supplement to my research and general intellectual curiosity. I wrote four of the books they summarized, and they f*cking nailed it.',
      '3 Times #1 New York Times Bestseller',
    ),
  ],
  summaries: [
    new TestimonialRecord(
      'Darcy Menard',
      'testimonial-darcy-menard.jpg',
      'I appreciate your careful approach to your content—one that aims for a high quality hand-crafted result instead of a mediocre one that scales. Please keep up the good work and the high bar of quality.',
    ),

    new TestimonialRecord(
      'Kannika Khannabha',
      'testimonial-kannika-khannabha.jpg',
      'I love the attention that your team has put in—like the logical organization of ideas, the clear and concise writing, the smart connections you make between books, and the choice of books you cover.',
    ),

    new TestimonialRecord(
      'Tom Lipscomb',
      'testimonial-tom-lipscomb.jpg',
      'I’ve read a dozen of your book guides already—they are extremely well done. Most other services are too superficial and are a poor way to understand the book. With Shortform I understand the book better than when I first read it.',
    ),
  ],

  pricing: [
    new TestimonialRecord(
      'Mark Smith',
      'testimonial-mark-smith.jpg',
      "I LOVE, LOVE, this business. I'm sorry it took me so long to find you. As a bibliophile, Shortform is a dream come true. The price is amazing; the selection superb, and the work product is exceptional. Kudos to the Shortform team.",
    ),

    new TestimonialRecord(
      'Delano Ducheck',
      'testimonial-delano-ducheck.jpg',
      'Shortform has been a life changer. I read a few chapters of a book each day, and then the Shortform guide after, and I actually feel I learn the ideas better than without Shortform.',
    ),

    new TestimonialRecord(
      'Amin Achour',
      'testimonial-amin-achour.jpg',
      "I want to thank you for all the work you're doing on Shortform. It's DEFINITELY and by FAR the best book guides in the world I have ever read.",
    ),
  ],

  about: [
    new TestimonialRecord(
      'George Dawn',
      'testimonial-george-dawn.jpg',
      'I think I have tried 4 or 5 similar services over the years, and yours is BY FAR the best. My wife and I have referred four people so far and more will join as we share the news about your platform.',
    ),
    new TestimonialRecord(
      'Joanne Duncanson',
      'testimonial-joanne-duncanson.jpg',
      'Shortform continues to knock it out of the part with by far the best book coverage out there (and I’ve read most of the services). The articles are also fantastic and teach me something new in small bites.',
    ),
    new TestimonialRecord(
      'Adrian Hood',
      'testimonial-adrian-hood.jpg',
      "I've read ten reviews so far and could not be happier. Originally, I took my own notes when reading books, but your notes are better than mine. I’ve been making Shortform a part of my daily routine.",
    ),
  ],
}
