import { waitTimer } from '@/helpers/timer'
import { EpisodeListItem, DocGroupItem } from './interfaces'
import { ListDescriptionView } from './docs.list'
import { ListingView } from '@/models/docs.listing'
import { backend } from '@/services/backend'
import { resetCacheByListItemType } from '@/helpers/cache'

/**
 * Podcasts logic used for podcast detail page.
 */
export class PodcastView implements ListingView {
  public podcast: DocGroupItem
  private _isHeaderCollapsed: boolean = false
  private _descriptionView: ListDescriptionView
  private _followView: PodcastFollowView

  constructor(podcast: DocGroupItem) {
    this.podcast = podcast
    this._descriptionView = new ListDescriptionView(
      { ...podcast, created: '', doc_type: 'podcast' },
      podcast.docs.data.length.toString(),
    )
    this._followView = new PodcastFollowView(podcast.is_followed, podcast.id)
  }
  get pageTitle(): string {
    return this.podcast.title
  }

  get filterTitle(): string {
    return 'Episodes'
  }

  get isHeaderCollapsed(): boolean {
    return this._isHeaderCollapsed
  }

  set isHeaderCollapsed(value: boolean) {
    this._isHeaderCollapsed = value
  }

  get dataLength(): number {
    return this.podcast.docs.data.length
  }

  get descriptionView(): ListDescriptionView {
    return this._descriptionView
  }

  get followView(): PodcastFollowView {
    return this._followView
  }

  get isFollowed(): boolean {
    return this._followView.isFollowed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowLimit(_limit: number): void {}

  public link(episode: EpisodeListItem): Record<string, unknown> {
    return {
      name: episode.position_chapter ? 'podcast.page' : 'podcast.preview',
      params: {
        url_slug: episode.url_slug,
      },
    }
  }
}

export class PodcastFollowView {
  private _podcastId: string
  private _hintTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {})
  public isFollowed: boolean
  public isLoading = false
  public label: 'Follow' | 'Following' | 'Unfollow'
  public hint: string | null = null

  constructor(isFollowed: boolean, podcastId: string) {
    this.isFollowed = isFollowed
    this._podcastId = podcastId
    this.label = this.isFollowed ? 'Following' : 'Follow'
  }

  /**
   * Follows / Unfollows the podcast, and update the button label accordingly.
   */
  async toggleFollow(): Promise<void> {
    if (this.isLoading) {
      return
    }

    this.isLoading = true

    const [result] = await Promise.all([
      this._sendToggleFollowRequest(),
      waitTimer(500),
    ])

    this.isFollowed = result.is_followed

    // Reset cache to force fresh data loading.
    // Otherwise, we mark the podcast as followed on the podcast page, for example,
    // then open the home page and the podcast is not marked as followed (additional
    // page refresh is needed to see the followed mark).
    await resetCacheByListItemType('podcast')

    this.isLoading = false
    this.updateLabel()

    if (this.isFollowed) {
      this.showHint()
    }
  }

  showHint(): void {
    clearTimeout(this._hintTimeout)

    this.hint =
      "You're following this podcast, check the latest episodes on the podcasts page for updates."

    this._hintTimeout = setTimeout(() => {
      this.hint = null
    }, 5000)
  }

  hideHint(): void {
    clearTimeout(this._hintTimeout)
    this.hint = null
  }

  /**
   * Updates the button label.
   *
   * If the podcast is not followed, the label is "Follow".
   * If the podcast is followed, the label is "Following".
   * If the podcast is followed and the user hovers over the button,
   * the label is "Unfollow".
   *
   * @param event - The mouse event that triggered the function.
   */
  updateLabel(event?: MouseEvent): void {
    if (this.isLoading) {
      return
    }

    if (event && this.isFollowed) {
      this.label = event.type === 'mouseenter' ? 'Unfollow' : 'Following'
      return
    }

    this.label = this.isFollowed ? 'Following' : 'Follow'
  }

  /**
   * Sends the backend request to follow / unfollow the podcast.
   *
   * If the podcast is followed, send a request to unfollow.
   * If the podcast is not followed, send a request to unfollow.
   *
   * @returns an object with the updated podcast data.
   */
  private async _sendToggleFollowRequest(): Promise<DocGroupItem> {
    return this.isFollowed
      ? await backend.unfollow(this._podcastId)
      : await backend.follow(this._podcastId)
  }
}
