import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EOCButton = _resolveComponent("EOCButton")!
  const _component_Annotator = _resolveComponent("Annotator")!
  const _component_AudioPlayer = _resolveComponent("AudioPlayer")!
  const _component_AudioPlayerDisabled = _resolveComponent("AudioPlayerDisabled")!
  const _component_ArticleLayout = _resolveComponent("ArticleLayout")!

  return (_openBlock(), _createBlock(_component_ArticleLayout, { article: _ctx.article }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["article-single__content-editor article-single__content-editor--no-border content-editor", _ctx.article.settings.cssClass]),
        style: _normalizeStyle({ 'font-family': _ctx.article.settings.fontFamily }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDocContentClick && _ctx.onDocContentClick(...args)))
      }, [
        _createElementVNode("img", {
          class: "article-single__cover",
          src: _ctx.article.cover_image,
          alt: ""
        }, null, 8, _hoisted_1),
        _createVNode(_component_Annotator, {
          content: _ctx.article.page
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "sf-chapter",
              oncopy: "return false",
              innerHTML: _ctx.getHtml()
            }, null, 8, _hoisted_2),
            (_ctx.article.isChapterEOC)
              ? (_openBlock(), _createBlock(_component_EOCButton, {
                  key: 0,
                  text: "Subscribe to Read Full Article",
                  "ios-text": "Full Subscription Required to Read More"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["content"])
      ], 6),
      (_ctx.article.audio && _ctx.article.controls.isPlayerRendered)
        ? (_openBlock(), _createBlock(_component_AudioPlayer, {
            key: 0,
            doc: _ctx.article
          }, null, 8, ["doc"]))
        : _createCommentVNode("", true),
      (
        !_ctx.article.audio && _ctx.article.has_audio && _ctx.article.controls.isPlayerRendered
      )
        ? (_openBlock(), _createBlock(_component_AudioPlayerDisabled, {
            key: 1,
            class: _normalizeClass(_ctx.article.controls.playerClass)
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["article"]))
}