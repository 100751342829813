import { ValidationError, ErrorsDict } from '@/models/error'

/**
 * Form validation state helper.
 *
 * Contains common logic related to forms validation:
 * a set of validation error and the `isValidated` flag
 * that shows if the user has alredy tried to submit the form.
 *
 * Handles <form> validation state, see
 * https://getbootstrap.com/docs/4.5/components/forms/#validation
 *
 * The helper is used like this:
 *
 * <template>
 *   <form
 *     role="form"
 *     novalidate
 *     @submit.prevent="save"
 *   >
 *     <textarea
 *       type="text"
 *       v-model="model.fieldName"
 *       class="form-control"
 *       :class="validation.stateClass('fieldName')"
 *     ></b-form-textarea>
 *     <FormError :errors="validation.errors" field="fieldName" />
 *     <button type="submit" class="btn btn-primary">Post</button>
 *   </form>
 * </template>
 * <script lang="ts">
 *
 * import FormError from '@/components/FormError.vue'
 * import { ValidationState } from '@/helpers/form'
 *
 * const EMPTY_MODEL = { id: null, text: '' }
 *
 * @Component({
 *   components: {
 *     FormError,
 *   },
 * })
 * export default class MyForm extends Vue {
 *
 *   validation: ValidationState = new ValidationState()
 *   model: MyModel = Object.assign({}, EMPTY_MODEL)
 *
 *   async save() {
 *     try {
 *       // Send the reply to backend.
 *       let reply = await backend.saveData(this.model)
 *
 *       // Reset the form state.
 *       this.validation.reset()
 *       this.model = Object.assign({}, EMPTY_MODEL)
 *     } catch (error) {
 *       this.validation.showErrors(error)
 *     }
 *   }
 * }
 * </script>
 */
export class ValidationState {
  /**
   * Validation errors
   */
  errors: ErrorsDict | null = null
  /**
   * True when the user has tried to submit the form.
   *
   * Used to show the validation messages only after submit,
   * not out when the user is filling in the form.
   */
  isValidated: boolean = false

  /**
   * Reset the form state, can be used after successful submit.
   */
  reset(): void {
    // Reset the form state.
    this.isValidated = false
    this.errors = null
  }

  /**
   * Show validation errors from the response object.
   */
  showErrors(error: any): void {
    this.isValidated = true
    if (error.response && error.response.status === 400) {
      this.errors = error.response.data.errors
    } else {
      throw error
    }
  }

  /**
   * Show validation errors from the ValidationError object.
   */
  show(error: ValidationError): void {
    this.isValidated = true
    this.errors = error.errors()
  }

  /**
   * Show validation errors from the ValidationError object.
   */
  showMessage(field: string, error: string): void {
    this.isValidated = true
    this.errors = { [field]: [error] }
  }

  showError(field: string, message: string): void {
    this.isValidated = true
    this.errors = this.errors || {}
    this.errors[field] = [message]
  }

  /**
   * Get all errors in a flat array.
   */
  getAllErrors(): string[] {
    if (!this.errors) {
      return []
    }

    return Object.values(this.errors).flat()
  }

  hasError(field: string): boolean {
    if (this.errors && this.errors[field]) {
      return true
    }
    return false
  }

  stateClass(field: string): string {
    if (this.isValidated === false) {
      return ''
    }
    if (this.errors && this.errors[field]) {
      return 'is-invalid'
    }
    return 'is-valid'
  }
}

/**
 * Set focus to the input and put cursor at the end of it.
 */
export function setFocus(input: HTMLElement): void {
  // The range manipulation is needed to set the cursor
  // into div.contenteditable.
  const range = document.createRange()
  if (input.lastChild) {
    range.setStartAfter(input.lastChild)
  }
  range.collapse(true)
  const sel = window.getSelection()
  // eslint hints that sel might be null - `Object is possibly 'null'.`,
  // so we use `if` guard here (probably this is for browsers that don't
  // support the window.getSelection() which should be present in all
  // modern browsers).
  if (sel) {
    sel.removeAllRanges()
    sel.addRange(range)
  }

  input.focus()
}

/**
 * Set focus to the input and put cursor at the start of it.
 */
export function setInputFocusStart(input: HTMLInputElement): void {
  input.focus()
  // Note: this only works for input / textarea,
  // not for div.contenteditable.
  input.setSelectionRange(0, 0)
  input.scrollTop = 0
}

/**
 * Create a funciton that delays given `fn` by 'delay' milliseconds.
 *
 * For example:
 *
 *   const delayedFn = debounce(myFunction, 500)
 *   delayedFn(arg1, arg2)
 */
export function debounce(fn: any, delay: number): (...args: any[]) => void {
  let timeoutID: any = null
  return function (...args: any[]): void {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => {
      // @ts-ignore
      fn.apply(this, args)
    }, delay)
  }
}

/**
 * Delay callback execution by `delay` milliseconds.
 *
 */
let delayTimeoutID: any = null
export function delay(callback: any, delay: number): void {
  clearTimeout(delayTimeoutID)
  delayTimeoutID = setTimeout(() => {
    callback()
  }, delay)
}

/**
 * Returns a function, that, when invoked, will only be triggered at most once
 * during a given window of time.
 */
export function throttle(fn: any, limit: number): (...args: any[]) => void {
  // Initially, we're not waiting.
  let waiting = false
  // We return a throttled function.
  return function (...args: any[]): void {
    // If we're not waiting.
    if (!waiting) {
      // Execute users function.
      // @ts-ignore
      fn.apply(this, args)
      // Prevent future invocations.
      waiting = true
      setTimeout(function () {
        // After a period of time.
        // And allow future invocations.
        waiting = false
      }, limit)
    }
  }
}
