import {
  QuestionnaireSection,
  OnboardingIntroPage,
  OnboardingTrialPage,
} from './long.onboarding.types'

const introPages: OnboardingIntroPage[] = [
  {
    title: "The <span>world's largest</span> summary library",
    image: 'intro-1.svg',
  },
  {
    title: '<span>Key points with no fluff</span> from nonfiction bestsellers',
    image: 'intro-2.svg',
  },
  {
    title: '<span>Read or listen</span> on desktop, mobile or Kindle',
    image: 'intro-3.svg',
  },
]

const questionnairePages: QuestionnaireSection[] = [
  {
    title: '_intro',
    id: '_intro',
    pages: [
      {
        id: 'intro',
        type: 'Text',
        title: 'Welcome to Shortform!',
        variableTitle: 'Welcome to Shortform, <span>{{name}}!</span>',
        subtitle: 'Help us personalize your experience!',
        image: {
          src: 'hand.png',
          width: 59,
          height: 59,
        },
        imageAnimation: 'wave',
        textSize: 'large',
        textAlignment: 'left',
      },
    ],
  },
  {
    id: 'about-you',
    title: 'Regarding you',
    pages: [
      {
        id: 'focus',
        type: 'Options',
        title: 'What best describes your goal?',
        options: [
          {
            label: 'Level up my career',
          },
          {
            label: 'Become more thoughtful',
          },
          {
            label: 'Increase my emotional awareness',
          },
          {
            label: 'Learn more about the world',
          },
          {
            label: 'None of the above',
            type: 'text-input',
            placeholder: 'Could you tell us about your goal?',
          },
        ],
      },
      {
        id: 'gender',
        type: 'Options',
        title: 'Please choose your gender',
        wrapInputs: true,
        options: [
          {
            label: 'Male',
          },
          {
            label: 'Female',
          },
          {
            label: 'Other',
          },
          {
            label: 'Prefer not to say',
            type: 'skip',
          },
        ],
      },
      {
        id: 'age',
        type: 'Options',
        title: 'Select your age',
        options: [
          { label: '18-25' },
          { label: '26-45' },
          { label: '46-55' },
          { label: '56+' },
        ],
      },
    ],
  },
  {
    id: 'aspiration',
    title: 'Goals',
    pages: [
      {
        id: 'goals',
        type: 'Options',
        title: 'What are your main priorities?',
        subtitle:
          'Select up to 3 priorities. They can change as you learn and grow!',
        multipleOptions: true,
        options: [
          {
            icon: 'money.svg',
            label: 'Make more money',
          },
          {
            icon: 'ladybugs.svg',
            label: 'Improve my family life',
          },
          {
            icon: 'trophy.svg',
            label: 'Increase my discipline',
          },
          {
            icon: 'brain.svg',
            label: 'Become smarter',
          },
          {
            icon: 'apple.svg',
            label: 'Live a healthier life',
          },
          {
            icon: 'hearts.svg',
            label: 'Improve my relationships',
          },
          {
            icon: 'balance.svg',
            label: 'Discover inner peace',
          },
          {
            icon: 'bed.svg',
            label: 'Level up my sex life',
          },
          {
            icon: 'kettlebell.svg',
            label: 'Exercise more frequently',
          },
          {
            icon: 'stopwatch.svg',
            label: 'Be more productive',
          },
          {
            icon: 'people.svg',
            label: 'Socialize more',
          },
        ],
      },
      {
        id: 'great-job',
        type: 'Text',
        title: 'Nice work!',
        variableTitle: 'Nice work, {{name}}!',
        subtitle:
          "Here's an achievement for you for being ambitious: the Rising Reader!",
        image: {
          src: 'great-job.svg',
          width: 257,
          height: 291,
        },
        imageAnimation: 'slide-up',
        textSize: 'large',
      },
      {
        id: 'book-summary-question',
        type: 'Options',
        title: 'Have you heard of book guides?',
        centerText: true,
        options: [
          { label: 'Yes' },
          { label: 'No' },
          { label: "I don't remember" },
        ],
      },
      {
        id: 'comparison',
        type: 'Text',
        title:
          'Shortform guides <span>save you time</span> by giving you the key points without fluff',
        image: {
          src: 'comparison.svg',
          width: 338,
          height: 230,
        },
      },
      {
        id: 'audio',
        type: 'Text',
        title:
          'Learn while walking, driving, or shopping, with <span>audio summaries</span>',
        image: {
          src: 'audio.svg',
          width: 375,
          height: 340,
        },
      },
      {
        id: 'daily-goal',
        type: 'DailyGoal',
        title: 'Select your reading pace',
        subtitle: 'This can be adjusted later',
      },
      {
        id: 'commit',
        type: 'Options',
        title: 'Set a growth streak goal',
        image: {
          src: 'growth.svg',
          width: 292,
          height: 149,
        },
        options: [
          {
            label: '1 week',
            hint: 'Trial',
          },
          {
            label: '2 weeks',
            hint: 'Committed',
          },
          {
            label: '1 month',
            hint: 'Focused',
          },
          {
            label: '3 months',
            hint: 'Empowered',
          },
        ],
      },
      {
        id: 'reading-when',
        type: 'Options',
        title: 'When do you like to learn something new?',
        options: [
          {
            icon: 'sun.svg',
            label: 'In the morning',
          },
          {
            icon: 'road.svg',
            label: 'On the way to work',
          },
          {
            icon: 'meal.svg',
            label: 'Eating lunch',
          },
          {
            icon: 'moon.svg',
            label: 'Before bedtime',
          },
          {
            icon: 'relax.svg',
            label: 'General free time',
          },
        ],
      },
      {
        id: 'notifications',
        type: 'Notifications',
        title: 'Turn on notifications to help you with:',
        image: {
          src: 'notifications.svg',
          width: 375,
          height: 156,
        },
        options: [
          {
            title: 'Optimal learning time',
            subtitle:
              'We will send you interesting ideas during times that fit your schedule',
          },
          {
            title: 'Achieving your reading goals',
            subtitle: 'Shortform will remind you to keep your learning going',
          },
          {
            title: 'Reading new content',
            subtitle: "We'll send fresh content that matches your interests",
          },
        ],
      },
      {
        id: 'review',
        type: 'Review',
        title:
          'More than <span>1 million customers trust</span> Shortform guides',
        review:
          "I LOVE Shortform as these are THE BEST summaries I've ever seen … and I've looked at lots of similar sites – like Blinkist and Get Abstracts and Soundview. first time I've ever found smart concise (yet detailed) book summaries that I can read on my phone as I walk on the treadmill",
        reviewer: 'Sophie Moore',
      },
    ],
  },
  {
    id: 'like-time',
    title: 'Preferences',
    pages: [
      {
        id: 'statements',
        type: 'Statements',
        title: 'Do you agree with this quote?',
        hideContinueButton: true,
        content: [
          'I love reading and learning, but I rarely have the time',
          'I care about self-improvement, however, I find it hard to remain disciplined',
          'I want to learn and grow without it being overly difficult',
        ],
      },
      {
        id: 'people-grow',
        type: 'Text',
        title: 'Over <span>1 million</span> happy users!',
        subtitle: `Our book guides will help you read faster and hit your learning goals! <span class="source">SimilarWeb, Nov 2024, worldwide</span>`,
        image: {
          src: 'world.svg',
          width: 344,
          height: 246,
        },
        textAlignment: 'left',
      },
      {
        id: 'books',
        type: 'Books',
        title: 'Does this book interest you?',
        hideContinueButton: true,
        content: [
          'atomic-habits',
          'the-48-laws-of-power',
          'the-7-habits-of-highly-effective-people',
          'how-to-win-friends-and-influence-people',
          'deep-work',
        ],
      },
    ],
  },
  {
    id: '_outro',
    title: '_outro',
    pages: [
      {
        id: 'finishing-setup',
        type: 'FinishingSetup',
        title: 'Your <span>reading plan</span> is ready!',
        titleLoading: 'Personalizing your <span>reading plan...</span>',
        hideContinueButton: true,
        steps: [
          {
            title: 'Growth targets',
            titleLoading: 'Analyzing growth targets',
            question: 'Do you get distracted easily?',
          },
          {
            title: 'Learning style',
            titleLoading: 'Setting learning style',
            question: 'Do you follow through with your plans?',
          },
          {
            title: 'Reading list',
            titleLoading: 'Curating reading list',
            question: 'Do you like to learn new things?',
          },
        ],
      },
      {
        id: 'commitment-pact',
        type: 'CommitmentPact',
        title: 'Growth Pledge',
        textInitial:
          "I must continue to learn and improve as a person in order to achieve my goals<br>That's why I am pledging to make reading a core part of my life.<br>I believe that Shortform will help me on my path to becoming a wiser and better person.",
        textFinal:
          "Hi, it's you from the future. It's an exciting day for you!<br>On {{currentDate}}, we pledged to begin a journey of learning & growth.<br>Now, in the future, I am wiser and live a more fulfilling life. I appreciate you for committing to the path of self improvement.",
        hideContinueButton: true,
      },
    ],
  },
]

const trialPages: OnboardingTrialPage[] = [
  {
    title: 'How our Free Trial works',
    steps: [
      {
        text: 'Free Premium access to Shortform. No payment now',
        icon: 'lock.svg',
        day: 'Today',
      },
      {
        text: 'We will remind you before your trial ends',
        icon: 'bell.svg',
        day: 'Day 3',
      },
      {
        text: 'Cancel your subscription to avoid a charge',
        icon: 'coin.svg',
        day: 'Day 5',
      },
    ],
  },
  {
    title:
      'We’ll notify you <span>2 days</span> before your free trial is over',
    image: {
      src: 'trial.svg',
      width: 201,
      height: 217,
    },
  },
]

export const onboardingPages = {
  get intro(): OnboardingIntroPage[] {
    return introPages
  },
  get questionnaire(): QuestionnaireSection[] {
    return questionnairePages
  },
  get trial(): OnboardingTrialPage[] {
    return trialPages
  },
}
