import { storage } from '@/helpers/storage'
import { RouteLocationNormalized } from 'vue-router'
import { waitTimer } from '@/helpers/timer'
import { currentRoute, navigate } from '@/helpers/router'

import {
  BookListItem,
  Questionnaire,
  QuestionnaireStatic,
  TagListItem,
  QuestionnaireProgressDot,
  QuestionnaireQuoteItem,
  QuestionnaireSupportItem,
  QuestionnaireReadingTimeItem,
  QuestionnaireReadingWhenItem,
} from './interfaces'
import {
  QuestionnaireStep,
  QuestionnaireReadingSupport,
  QuestionnaireReadingTime,
  QuestionnaireReadingWhen,
} from './questionnaire_types'
import { backend } from '@/services/backend'
import { Mixpanel } from '@/analytics/mixpanel'
import { Sentry } from '@/services/sentry'

/*
 * Questionnaire Data class.
 *
 * We use this object to keep user choises during the questionnaire, and then
 * send the data to backend by dropping this object as a POST request data,
 * this is the reason why variables here are named using underscores.
 */
export class QuestionnaireData implements Questionnaire {
  public tags: TagListItem[] = []
  public book_one_id: string = ''
  public book_one_liked: boolean | null = null
  public book_two_id: string = ''
  public book_two_liked: boolean | null = null
  public book_three_id: string = ''
  public book_three_liked: boolean | null = null
  public quote_one_liked: boolean | null = null
  public quote_two_liked: boolean | null = null
  public quote_three_liked: boolean | null = null
  public reading_support: QuestionnaireReadingSupport | null = null
  public reading_time: QuestionnaireReadingTime | null = null
  public reading_when: string[] = []

  constructor() {
    this.load()
  }

  // Functions to save to localStorage and load data
  save(step: QuestionnaireStep): void {
    storage.setItem('sf_oq_step', step)
    storage.setItem('sf_oq_data', JSON.stringify(this))
  }

  load(): void {
    const data = storage.getItem('sf_oq_data')
    if (data) {
      Object.assign(this, JSON.parse(data))
    }
  }
}

/*
 * Questionnaire view.
 *
 * This is the "main" view for the whole questionnaire, it's responsible for the
 * following:
 * - keep track of questionnaire steps to show needed components
 * - for books and quotes also keep track of currently shown book/quote inside
 *   the step
 * - create and keep track of progress dots: this is a progress bar for qustionnaire
 * - for now: get temporary mocked static data from auxiliary data class.
 */
export class QuestionnaireView {
  private _data: QuestionnaireData = new QuestionnaireData()
  private _STEPS: QuestionnaireStep[] = []
  private _nextPage: Record<string, any> = { name: 'billing' }
  private _runLoadingTimer: boolean = true

  public currentStepIndex: number = 0
  public categories: TagListItem[] = []
  public progressDots: QuestionnaireProgressDot[] = []
  public books: BookListItem[] = []
  public finishButtonDisabled: boolean = false

  // Static data
  public quotes: QuestionnaireQuoteItem[]
  public supportItems: QuestionnaireSupportItem[]
  public readingTimeItems: QuestionnaireReadingTimeItem[]
  public readingWhenItems: QuestionnaireReadingWhenItem[]

  /*
   * Constructor.
   */
  constructor() {
    this.populateSteps()
    // A list of progress dots, we need to keep track of current step and
    // show progress UI accordingly, having in mind that we need to have fewer
    // dots than the number of steps:
    // - all three books are one dot
    // - all three quotes are ont dot
    // - "support block" (reading support, time and when) is one dot
    this.progressDots = [
      { stepIndexes: [1] }, // Categories
      { stepIndexes: [2, 3, 4] }, // Books 1, 2, 3
      { stepIndexes: [5, 6, 7] }, // Quotes 1, 2, 3
      { stepIndexes: [8, 9, 10] }, // Reading support, time, when
      { stepIndexes: [11] }, // Finish
    ]

    // Get temporary static data
    const staticData = new QuestionnaireStaticData()
    this.quotes = staticData.quotes
    this.supportItems = staticData.supportItems
    this.readingTimeItems = staticData.readingTimeItems
    this.readingWhenItems = staticData.readingWhenItems

    this.getPageFromRoute(currentRoute().value)
    this._nextPage = {
      name: 'billing',
      state: { firstVisit: 'true' },
    }
  }

  /*
   * Initialization logic.
   *
   * This method must be called after the object creation.
   */
  async init(): Promise<void> {}

  private populateSteps(): void {
    for (const step in QuestionnaireStep) {
      this._STEPS.push(
        QuestionnaireStep[step as keyof typeof QuestionnaireStep],
      )
    }
  }

  /*
   * Is the current progress dot active for desktop width.
   */
  isDesktopDotActive(dot: QuestionnaireProgressDot): boolean {
    // Current step index is within defined dot step indexes.
    return dot.stepIndexes.indexOf(this.currentStepIndex) >= 0
  }

  /*
   * Is the current progress doc active for mobile width.
   */
  isMobileDotActive(dot: QuestionnaireProgressDot): boolean {
    return dot.stepIndexes[0] <= this.currentStepIndex
  }

  /*
   * Get page from route.
   *
   * This method is used to handle routes change during steps change.
   * It gets the current route and finds the matchig step, makes found step active.
   */
  public getPageFromRoute(route: RouteLocationNormalized): void {
    // Route name is exactly 'questionnaire'
    if (route.name === 'questionnaire') {
      // Parent view, start the questionnaire
      this.currentStepIndex = 0
      this._data.save(QuestionnaireStep.start)
      navigate({ name: this.currentStep })
      return
    }
    // Route contains 'questionnaire' in it
    if (route.path.includes('questionnaire')) {
      const page = route.name
      if (page) {
        this.restoreChoices()
        this.setStep(page as QuestionnaireStep)
      } else {
        // Wrong route, redirect to the "next" page.
        navigate(this._nextPage)
      }
    } else {
      this.currentStepIndex = 0
    }
  }

  /*
   * Abort questionnaire and redirect to the next page.
   *
   * This is a special error handler method, if any error appears during the
   * questionnaire, skip it and redirect the user to app.
   */
  public abortQuestionnaire(error: any): void {
    this._data.save(QuestionnaireStep.finish)
    Sentry.withScope((scope) => {
      scope.setExtra('message', 'Onboarding Questionnaire aborted on error')
      Sentry.captureException(error)
    })
    navigate(this._nextPage)
  }

  /*
   * Finish the questionnaire.
   *
   * Send the collected data to backend.
   */
  public async finish(): Promise<void> {
    try {
      this.finishButtonDisabled = true
      await backend.saveQuestionnaire(this._data)
    } catch (error) {
      this.abortQuestionnaire(error)
      // To avoid double navigation
      return
    }

    navigate(this._nextPage)
  }

  /*
   * Switch to the next step.
   *
   * View uses this method to switch to the next step, and also
   * update the URL in browser address field.
   */
  public next(): void {
    this.currentStepIndex += 1
    this._data.save(this.currentStep)
    navigate({ name: this.currentStep })
  }

  /*
   * Switch to the previous step.
   *
   * View uses this method to switch to the previous step, and also
   * update the URL in browser address field
   */
  public prev(): void {
    this.currentStepIndex -= 1
    this._data.save(this.currentStep)
    navigate({ name: this.currentStep })
  }

  /*
   * Mobile width back button click.
   *
   * This button is also available on mobile width for Finish step, this means
   * that we need to handle the case when the user jumped to the Finish step
   * from "Support" step.
   */
  public mobileBackClick(): void {
    if (
      this.currentStep === QuestionnaireStep.finish &&
      this._data.reading_support === QuestionnaireReadingSupport.none
    ) {
      this.setStep(QuestionnaireStep.support)
      navigate({ name: this.currentStep })
    } else {
      this.prev()
    }
  }

  /*
   * Get current quesionnaire step by current step index.
   */
  public get currentStep(): QuestionnaireStep {
    return this._STEPS[this.currentStepIndex]
  }

  /*
   * Set the step directly.
   *
   */
  private setStep(step: QuestionnaireStep): void {
    for (let i = 0; i < this._STEPS.length; i += 1) {
      if (this._STEPS[i] === step) {
        this.currentStepIndex = i
        this._data.save(this.currentStep)
        break
      }
    }
  }

  /*
   * Get categories from backend.
   */
  public async getCategories(): Promise<void> {
    // Reset to show loading timer again
    this.resetRunLoadingTimer()
    try {
      const response = await backend.getQuestionnaireTags()
      this.categories = response.data
    } catch (error) {
      this.abortQuestionnaire(error)
    }
  }

  /*
   * Make category icon class.
   */
  public categoryIcon(categoryName: string): string {
    const name = categoryName.toLowerCase().replace('/', '-')
    return `onboarding-category__icon-c iconfont icategory-${name}`
  }

  /*
   * Select category handler.
   *
   * We need to preserve the order of categories selection so we use an Array,
   * the first clicked category is the first one in the list, and so on:
   * this method adds category to the list of selected categories if the category
   * wasn't selected before, and removes it from the list of selected categories,
   * if the category was selected before.
   */
  public selectCategory(category: TagListItem): void {
    // Toggle category activity on click
    if (!this.isCategoryActive(category)) {
      // Was inactive and becomes active, add to list
      this._data.tags.push(category)
      Mixpanel.trackQuestionnaireCategory(this._data.tags.map((c) => c.name))
    } else {
      // Was active and becomes inactive, remove from list
      const index = this.selectedCategoryIdx(category)
      if (index > -1) {
        this._data.tags.splice(index, 1)
      }
    }
  }

  public get selectedCategoriesQuantity(): number {
    return this._data.tags.length
  }

  /*
   * Is the category selected.
   */
  private isCategoryActive(category: TagListItem): boolean {
    return this.selectedCategoryIdx(category) > -1
  }

  /*
   * Helper method to get the index of category in selected categories.
   *
   * Just .indexOf() doesn't work for the case of objects array, so I used this
   * solution: https://stackoverflow.com/a/16008853/10127328
   */
  private selectedCategoryIdx(category: TagListItem): number {
    return this._data.tags
      .map(function (e) {
        return e.id
      })
      .indexOf(category.id)
  }

  /*
   * Disable next button until the user selects at least 3 categories.
   */
  public get hasAtLeastThreeCategoriesSelected(): boolean {
    if (this._data.tags.length >= 3) {
      return true
    }
    return false
  }

  /*
   * Get current quote.
   */
  public get currentQuote(): QuestionnaireQuoteItem {
    const quotes = this.quotes
    switch (this.currentStep) {
      case QuestionnaireStep.quoteStepOne:
        return quotes[0]
      case QuestionnaireStep.quoteStepTwo:
        return quotes[1]
      case QuestionnaireStep.quoteStepThree:
        return quotes[2]
      default:
        return quotes[0]
    }
  }

  /*
   * Save quote choice to data object and switch to the next step.
   */
  public saveQuote(liked: boolean): void {
    switch (this.currentStep) {
      case QuestionnaireStep.quoteStepOne:
        this._data.quote_one_liked = liked
        break
      case QuestionnaireStep.quoteStepTwo:
        this._data.quote_two_liked = liked
        break
      case QuestionnaireStep.quoteStepThree:
        this._data.quote_three_liked = liked
        break
      default:
        throw new Error(`Unexpected saveQuote call: ${this.currentStep}`)
    }
    this.next()
  }

  public isQuoteLiked(): boolean | null {
    switch (this.currentStep) {
      case QuestionnaireStep.quoteStepOne:
        return this._data.quote_one_liked
      case QuestionnaireStep.quoteStepTwo:
        return this._data.quote_two_liked
      case QuestionnaireStep.quoteStepThree:
        return this._data.quote_three_liked
      default:
        // Happens on transition to previous/next component
        return null
    }
  }

  /*
   * Get books from backend.
   */
  public async getBooks(): Promise<void> {
    try {
      const tagIds = this._data.tags.map((e) => e.id)
      const response = await backend.getBooksByTags(tagIds)
      this.books = response.data
    } catch (error) {
      this.abortQuestionnaire(error)
    }
  }

  /*
   * Get current book.
   */
  public get currentBook(): BookListItem {
    switch (this.currentStep) {
      case QuestionnaireStep.bookStepOne:
        return this.books[0]
      case QuestionnaireStep.bookStepTwo:
        return this.books[1]
      case QuestionnaireStep.bookStepThree:
        return this.books[2]
      default:
        return this.books[0]
    }
  }

  /*
   * Save current book to the QuestionnaireData.
   */
  public saveBook(liked: boolean): void {
    switch (this.currentStep) {
      case QuestionnaireStep.bookStepOne:
        this._data.book_one_id = this.currentBook.id
        this._data.book_one_liked = liked
        break
      case QuestionnaireStep.bookStepTwo:
        this._data.book_two_id = this.currentBook.id
        this._data.book_two_liked = liked
        break
      case QuestionnaireStep.bookStepThree:
        this._data.book_three_id = this.currentBook.id
        this._data.book_three_liked = liked
        break
      default:
        throw new Error(`Unexpected saveBook call: ${this.currentStep}`)
    }
    this.next()
  }

  public isBookLiked(): boolean | null {
    switch (this.currentStep) {
      case QuestionnaireStep.bookStepOne:
        return this._data.book_one_liked
      case QuestionnaireStep.bookStepTwo:
        return this._data.book_two_liked
      case QuestionnaireStep.bookStepThree:
        return this._data.book_three_liked
      default:
        // Happens on transition to previous/next component
        return null
    }
  }

  /*
   * Show "Finding your recommendations..." screen
   *
   * The logic here is: show the screen while we get books by tags from backend
   * but at least for 3 seconds.
   */
  public async loadBooks(): Promise<void> {
    if (this._data.tags.length < 3) {
      this.setStep(QuestionnaireStep.categories)
      navigate({ name: this.currentStep })
      return
    }
    if (this._runLoadingTimer) {
      // Show animation for 3 seconds and show next step
      await Promise.all([waitTimer(3000), this.getBooks()])
      this._runLoadingTimer = false
    } else {
      this.prev()
    }
  }

  /*
   * Reset loading timer and choices.
   *
   * We call this method when we are on Categories screen. If this is just
   * a start of questionnaire it just resets defaults again, but if we went
   * back to categories from books, we need to reset like/dislike choices for the
   * case user selects new categories and we don't have pre-selected likes/dislikes
   * for them.
   */
  public resetRunLoadingTimer(): void {
    this._runLoadingTimer = true
    this._data.book_one_liked = null
    this._data.book_one_id = ''
    this._data.book_two_liked = null
    this._data.book_two_id = ''
    this._data.book_three_liked = null
    this._data.book_three_id = ''
  }

  /*
   * Get loading timer state.
   */
  public get loading(): boolean {
    return this._runLoadingTimer
  }

  /*
   * Disable loading timer, used only in tests.
   */
  public disableLoadingTimer(): void {
    this._runLoadingTimer = false
  }

  /*
   * Click support item.
   */
  public supportItemClick(supportItem: QuestionnaireSupportItem): void {
    this._data.reading_support = supportItem.value
  }

  /*
   * Return support item active CSS class if the item is active.
   */
  public supportItemActiveClass(supportItem: QuestionnaireSupportItem): string {
    return this._data.reading_support === supportItem.value
      ? 'onboarding-support__item--active'
      : ''
  }

  /*
   * Do we have at least one support item selected?
   *
   * This function is used to unblock Next button in the component.
   */
  public get isSupportItemSelected(): boolean {
    return this._data.reading_support !== null
  }

  /*
   * Support step next button click.
   *
   * The additional logic here: if the user chooses the support item "None"
   * we go directly to finish step.
   */
  public supportNextClick(): void {
    if (this._data.reading_support === QuestionnaireReadingSupport.none) {
      this.setStep(QuestionnaireStep.finish)
      navigate({ name: this.currentStep })
    } else {
      this.next()
    }
  }

  /*
   * Click reading time item.
   */
  public readingTimeItemClick(
    readingTimeItem: QuestionnaireReadingTimeItem,
  ): void {
    this._data.reading_time = readingTimeItem.value
  }

  /*
   * Return reading time active CSS class if the item is active.
   */
  public readingTimeItemActiveClass(
    readingTimeItem: QuestionnaireReadingTimeItem,
  ): string {
    return this._data.reading_time === readingTimeItem.value
      ? 'onboarding-time__item--active'
      : ''
  }

  /*
   * Do we have at least one reading time item selected?
   *
   * This function is used to unblock Next button in the component.
   */
  public get isReadingTimeSelected(): boolean {
    return this._data.reading_time !== null
  }

  /*
   * Click reading when item.
   *
   * Multiple selection is allowed for these items, so we toggle the state of
   * each item individually, without affecting the rest of items. We keep track
   * of selected items using the array, we add an item if it wasn't selected
   * and remove the item if it was selected before (toggle the state).
   */
  public readingWhenItemClick(readingWhen: QuestionnaireReadingWhenItem): void {
    readingWhen.active = !readingWhen.active
    this._data.reading_when = this.readingWhenItems
      .filter((item) => item.active)
      .map((item) => item.value)
  }

  /*
   * Do we have at least one reading when item selected?
   *
   * This function is used to unblock Next button in the component.
   */
  public get isReadingWhenSelected(): boolean {
    return this._data.reading_when.length > 0
  }

  /*
   * Restore choices for ReadingWhen component.
   *
   */
  private restoreChoices(): void {
    this.readingWhenItems.forEach((readingWhenItem) => {
      if (this._data.reading_when.indexOf(readingWhenItem.value) > -1) {
        readingWhenItem.active = true
      }
    })
  }
}

class QuestionnaireStaticData implements QuestionnaireStatic {
  _quotes = [
    {
      background_image: 'quote-01.jpg',
      author: 'Warren Buffett',
      text: '“The best investment you can make is an investment in yourself… The more you learn, the more you’ll earn.”',
      theme: '',
    },
    {
      background_image: 'quote-02-v2.jpg',
      author: 'René Descartes',
      text: '“The reading of all good books is like a conversation with the finest minds of past centuries.”',
      theme: '',
    },
    {
      background_image: 'quote-03-v2.jpg',
      author: 'Oprah Winfrey',
      text: '“You must feed your mind with reading material, thoughts, and ideas that open you to new possibilities.”',
      theme: '',
    },
  ]
  _supportItems = [
    {
      title: 'Coach',
      text: 'Help me read more as a habit',
      icon: 'support-01.svg',
      value: QuestionnaireReadingSupport.coach,
    },
    {
      title: 'Supporter',
      text: 'Give me friendly nudges when needed',
      icon: 'support-02.svg',
      value: QuestionnaireReadingSupport.supporter,
    },
    {
      title: 'None',
      text: 'I’m fine by myself, thanks.',
      icon: 'support-03.svg',
      value: QuestionnaireReadingSupport.none,
    },
  ]
  _readingTimeItems = [
    {
      text: 'About 15 minutes',
      value: QuestionnaireReadingTime.about_15_minutes,
      icon: 'time-01.svg',
    },
    {
      text: 'About 30 minutes',
      value: QuestionnaireReadingTime.about_30_minutes,
      icon: 'time-02.svg',
    },
    {
      text: 'About 45 minutes',
      value: QuestionnaireReadingTime.about_45_minutes,
      icon: 'time-03.svg',
    },
    {
      text: 'More than 1 hour',
      value: QuestionnaireReadingTime.more_than_1_hour,
      icon: 'time-04.svg',
    },
    {
      text: 'Any spare time',
      value: QuestionnaireReadingTime.any_spare_time,
      icon: 'time-05.svg',
    },
  ]
  _readingWhenItems = [
    {
      text: 'In the morning',
      icon: 'period-01.svg',
      value: QuestionnaireReadingWhen.in_the_morning,
      active: false,
    },
    {
      text: 'While commuting',
      icon: 'period-02.svg',
      value: QuestionnaireReadingWhen.while_commuting,
      active: false,
    },
    {
      text: 'During my lunch break',
      icon: 'period-03.svg',
      value: QuestionnaireReadingWhen.during_my_lunch_break,
      active: false,
    },
    {
      text: 'Around dinnertime',
      icon: 'period-04.svg',
      value: QuestionnaireReadingWhen.around_dinnertime,
      active: false,
    },
    {
      text: 'Before going to sleep',
      icon: 'period-05.svg',
      value: QuestionnaireReadingWhen.before_going_to_sleep,
      active: false,
    },
    {
      text: 'Any spare time',
      icon: 'period-06.svg',
      value: QuestionnaireReadingWhen.any_spare_time,
      active: false,
    },
  ]

  public get quotes(): QuestionnaireQuoteItem[] {
    return this._quotes
  }

  public get supportItems(): QuestionnaireSupportItem[] {
    return this._supportItems
  }

  public get readingTimeItems(): QuestionnaireReadingTimeItem[] {
    return this._readingTimeItems
  }

  public get readingWhenItems(): QuestionnaireReadingWhenItem[] {
    return this._readingWhenItems
  }
}
