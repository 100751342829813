import { debounce } from '@/helpers/form'
import { assertNotNull } from '@/helpers/typing'
import { SCROLL_INITIAL_SHOW_LIMIT, SCROLL_BATCH_SIZE } from '@/models/doc'
import { emitter } from '@/services/mitt'
import { ListingView } from './docs.listing'

/**
 * Helper for document list incremental rendering.
 *
 * We show the first SCROLL_INITIAL_SHOW_LIMIT documents initially and then,
 * once we scroll to the bottom, add SCROLL_BATCH_SIZE more documents to the
 * view.
 *
 * Actual list of the documents is in the DocsView where we
 * have a `_showLimit` variable.
 * We sill load all documents from the backend at once, so
 * we can search / filter them on the client, but render only
 * part of the whole list.
 */
export class DocsScroll {
  // Books, Articles or Collections
  private _view: ListingView
  // Current number of documents to show.
  private _showLimit: number = SCROLL_INITIAL_SHOW_LIMIT

  // Saved scroll event function, we store it to be able to detach the event
  // handler on parent component destroy (the component is expected to call
  // the `removeScrollEventListener` method).
  private _scrollHandler: any

  // `true` if there are event listeners active.
  isListening: boolean = false

  constructor(view: ListingView) {
    this._view = view
    this.initEventListeners()
  }

  initEventListeners(): void {
    this.isListening = true

    this._scrollHandler = debounce(() => {
      this.appScrollHandler()
    }, 30)

    // See @/app/App.vue where we $emit the appScroll event.
    emitter.on('appScroll', this._scrollHandler)

    this.headerObserver.observe(this.headerObserverTarget)
  }

  /**
   * Detach the scroll event handler.
   */
  removeScrollEventListener(): void {
    emitter.off('appScroll', this._scrollHandler)
    this._scrollHandler = undefined

    this.headerObserver.unobserve(this.headerObserverTarget)

    this.isListening = false
  }

  /*
   * The `#app` HTML element, scrollable view.
   */
  get appEl(): HTMLElement {
    return assertNotNull(document.getElementById('app'))
  }

  /*
   * The `#observer-screen-top-target`, that is used by the observer to detect
   * if the scroll position is at the top of the page.
   */
  get headerObserverTarget(): HTMLElement {
    return assertNotNull(document.getElementById('observer-screen-top-target'))
  }

  /*
   * Collapses / expands the header depending on the scroll position.
   */
  private headerObserver = new IntersectionObserver(
    (entries) => {
      this._view.isHeaderCollapsed = !entries[0].isIntersecting
    },
    {
      threshold: 1,
    },
  )

  /**
   * Update number of documents to show depending on scroll position.
   */
  appScrollHandler(): void {
    const oldLimit = this._showLimit

    // Visible page height
    const appHeight = this.appEl.offsetHeight
    // Virtual page height, with scroll
    const scrollHeight = this.appEl.scrollHeight
    // Current scroll position
    const scrollTop = this.appEl.scrollTop

    // Add more documents to show when we are two screens before the end.
    // Don't try adding more documents if we already show them all.
    if (
      scrollHeight - scrollTop < 2 * appHeight &&
      this._showLimit <= this._view.dataLength
    ) {
      this._showLimit += SCROLL_BATCH_SIZE
    }

    // If we are on the first "virtual" page, reset number of documents to show.
    if (scrollTop < appHeight) {
      this._showLimit = SCROLL_INITIAL_SHOW_LIMIT
    }
    const isUpdated = this._showLimit !== oldLimit
    if (isUpdated) {
      this._view.setShowLimit(this._showLimit)
    }
  }
}
