
import { Vue } from 'vue-class-component'
import { Options, Watch } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue' // @ is an alias to /src
import HeaderRoot from '@/app/components/header/Root.vue'
import BillingData from '@/app/components/billing/BillingData.vue'
import BillingDataPaid from '@/app/components/billing/BillingDataPaid.vue'
import BillingDataPaidApple from '@/app/components/billing/BillingDataPaidApple.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import ContentWrapper from '@/components/ui/ContentWrapper.vue'
import BillingDataRedesigned from '@/app/components/billing/redesign15255/BillingDataRedesign.vue'

import { billingRedesign15255Variant } from '@/services/ab'

import { wait } from '@/helpers/vue-wait'
import { assertNotNull } from '@/helpers/typing'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import {
  BillingView,
  createBilling,
  isAppleWeb,
  isGoogleWeb,
} from '@/models/billing'

@Options({
  components: {
    Layout,
    HeaderRoot,
    BillingData,
    BillingDataPaid,
    BillingDataPaidApple,
    ContentWrapper,
    LoadingSpinner,
    BillingDataRedesigned,
  },
})
export default class BillingPage extends Vue {
  firstVisit: string = 'false'

  billing: BillingView | null = null
  redirectDelay: number = 6000

  isAppleWebBilling: boolean = false
  isGoogleWebBilling: boolean = false

  async beforeMount(): Promise<void> {
    this.firstVisit = window.history.state.firstVisit
    wait.start(this, 'Loading Billing')
    await this.refreshBilling()
  }

  async refreshBilling(): Promise<void> {
    wait.start(this, 'Loading Billing')
    wait.end(this, 'Error Loading Billing')
    try {
      const response = await backend.getBilling()
      this.billing = await createBilling(response)
      this.isAppleWebBilling = await isAppleWeb(response)
      this.isGoogleWebBilling = await isGoogleWeb(response)
    } catch (error) {
      wait.start(this, 'Error Loading Billing')
      // The error here is usually NetworkError or Timeout, but
      // we still capture all errors to Sentry to be aware if
      // error rate increases.
      Sentry.withScope(function (scope) {
        scope.setFingerprint(['billing-loading-error'])
        Sentry.captureException(error)
      })
    } finally {
      wait.end(this, 'Loading Billing')
    }
  }

  /**
   * Handles the redirect after subscription has been made.
   */
  @Watch('billing.subscribedAlertAndRedirect')
  async subscribedTo(value: boolean /* , old: boolean */): Promise<void> {
    if (value !== true) {
      return
    }

    // Redirect to 'books' page after delay when switching to the paid plan.
    setTimeout(() => {
      const currentRoute = this.$router.currentRoute.value
      if (currentRoute.name !== 'billing') {
        // The user has already navigated out of the billing page,
        // skip the redirect.
        return
      }
      this.$router.push({ name: 'books' })
    }, this.redirectDelay)
  }

  isLoadingBilling(): boolean {
    return wait.is(this, 'Loading Billing')
  }

  isErrorLoadingBilling(): boolean {
    return wait.is(this, 'Error Loading Billing')
  }

  get isFree(): boolean {
    const billing = assertNotNull(this.billing)
    return !billing.isPaidPlan
  }

  get isStripePaid(): boolean {
    const billing = assertNotNull(this.billing)
    return billing.isPaidPlan && !billing.isAppStore()
  }

  get isApplePaid(): boolean {
    const billing = assertNotNull(this.billing)
    return billing.isPaidPlan && billing.isAppStore()
  }

  get isBillingRedesignVariantC(): boolean {
    return billingRedesign15255Variant() === 'C'
  }
}
