import Books from './views/Books.vue'

import Articles from './views/Articles.vue'
import Page404 from './views/Page404.vue'
import BookSummaryPage from './views/BookSummaryPage.vue'
import PodcastEpisodeSummaryPage from './views/PodcastEpisodeSummaryPage.vue'
import ArticlePage from './views/ArticlePage.vue'
import Billing from './views/Billing.vue'
import Login from './views/Login.vue'
import Signup from './views/Signup.vue'
import PasswordForgot from './views/PasswordForgot.vue'
import PasswordReset from './views/PasswordReset.vue'
import HighlightsAll from './views/HighlightsAll.vue'
import MyLibrary from './views/MyLibrary.vue'

import Help from './views/Help.vue'
import Export from './views/Export.vue'
import Questionnaire from './views/Questionnaire.vue'
import LongOnboarding from './views/LongOnboarding.vue'
import Search from './views/Search.vue'
import Discover from './views/Discover.vue'
import Settings from './views/Settings.vue'

import QuestionnaireStart from '@/app/components/questionnaire/StartPage.vue'
import QuestionnaireCategories from '@/app/components/questionnaire/Categories.vue'
import QuestionnaireBook from '@/app/components/questionnaire/Book.vue'
import QuestionnaireQuote from '@/app/components/questionnaire/Quote.vue'
import QuestionnaireSupport from '@/app/components/questionnaire/Support.vue'
import QuestionnaireReadingTime from '@/app/components/questionnaire/ReadingTime.vue'
import QuestionnaireReadingWhen from '@/app/components/questionnaire/ReadingWhen.vue'
import QuestionnaireFinish from '@/app/components/questionnaire/Finish.vue'

import BookCommunity from '@/app/components/community/Community.vue'
import BookCommunityThread from '@/app/components/community/Thread.vue'
import BookCommunityThreadEdit from '@/app/components/community/ThreadEdit.vue'
import BookContent from '@/app/components/doc/reading/Content.vue'
import DocPreviewPage from '@/app/components/doc/preview/DocPreviewPage.vue'
import BookHighlights from '@/app/components/doc/highlights/Highlights.vue'
import HighlightPage from '@/app/components/book/highlights/HighlightPage.vue'
import BookExport from '@/app/components/book/BookExport.vue'

import ListPage from '@/app/views/ListPage.vue'
import PodcastPage from '@/app/views/PodcastPage.vue'

import LibraryBooks from '@/app/components/library/LibraryBooks.vue'
import LibraryArticles from '@/app/components/library/LibraryArticles.vue'
import LibraryEpisodes from '@/app/components/library/LibraryEpisodes.vue'
import LibraryCustomLists from '@/app/components/library/LibraryCustomLists.vue'

import { setLocation } from '@/helpers/routes'
import { auth } from '@/services/auth'
import { PageType as BooksSpecialPageType } from '@/models/docs.listing'
import { ArticlesPageType, PodcastsPageType } from '@/models/docs.listing'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import {
  isLongOnboardingVariant,
  longOnboarding15290Experiment,
  billingRedesign15255Experiment,
} from '@/services/ab'

import AccountDeletionConfirmation from '@/app/views/AccountDeletionConfirmation.vue'
import Podcasts from '@/app/views/Podcasts.vue'
import PodcastsSearchPage from '@/app/components/listing/PodcastsSearchPage.vue'
import EpisodesLibraryListingPage from '@/app/components/listing/EpisodesLibraryListingPage.vue'

// Results in something like 'reading|all|new|popular|favorites|finished'
const booksListingPageRegex = Object.keys(BooksSpecialPageType).join('|')
const articlesListingPageRegex = Object.keys(ArticlesPageType).join('|')
const episodesListingPageRegex = Object.keys(PodcastsPageType).join('|')

const NopComponent = {
  template: '<span></span>',
}

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * The page's title. It's used in the <meta name="title"> tag
     * and in the backlink text in the navigation history
     */
    title?: string
    /**
     * Whether or not this is a public page (no auth required for access).
     */
    isPublicPage?: boolean
    /**
     * Whether or not this is a root page (a page in the root of the app navigation,
     * such as the ones accessible via the navbar).
     */
    isRootPage?: boolean
    /**
     * The name of this route's parent page. We use this in the backlink logic
     * when a page isn't a root page and we can't find one from the path.
     */
    parentPage?: string
    /**
     * If `true`, the page won't be saved to the navigation history.
     */
    dontSaveToHistory?: boolean
    /**
     * Whether or not to cache the the state of this page in the
     * `KeepAlive` component in `App.vue`. If `keepAlive` is `true`,
     * then `componentName` must also be informed.
     */
    keepAlive?: boolean
    /**
     * The name of this route's component. Vue doesn't have a public
     * API to get a component's name when using the `<script setup>`
     * syntax, so we have to manually define it here to be able to
     * access it.
     */
    componentName?: string
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'site_index',
    meta: { title: 'Discover', isPublicPage: true },
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/', next)
    },
    component: NopComponent,
  },
  {
    path: '/terms',
    name: 'site_terms',
    meta: { title: 'Terms', isPublicPage: true },
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/terms', next)
    },
    component: NopComponent,
  },
  {
    path: '/privacy',
    name: 'site_privacy',
    meta: { title: 'Privacy', isPublicPage: true },
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/privacy', next)
    },
    component: NopComponent,
  },
  {
    path: '/extension-auth',
    name: 'extension_auth',
    meta: {
      title: 'Extension Authentication',
      dontSaveToHistory: true,
    },
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/extension-auth', next)
    },
    component: NopComponent,
  },
  {
    path: '/app/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      isPublicPage: true,
    },
  },
  {
    path: '/app/signup',
    name: 'signup',
    component: Signup,
    async beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any,
    ): Promise<void> {
      const variant = await longOnboarding15290Experiment()
      if (isLongOnboardingVariant(variant)) {
        next({ name: 'long_onboarding' })
      } else {
        next()
      }
    },
    meta: {
      title: 'Signup',
      isPublicPage: true,
    },
  },
  {
    path: '/app/password/forgot',
    name: 'password_forgot',
    component: PasswordForgot,
    meta: {
      title: 'Forgot Password',
      isPublicPage: true,
    },
  },
  {
    path: '/app/password/reset',
    name: 'password_reset',
    component: PasswordReset,
    meta: {
      title: 'Reset Password',
      isPublicPage: true,
    },
  },
  {
    path: '/app',
    name: 'home',
    component: Discover,
    meta: {
      title: 'Discover',
      isRootPage: true,
    },
  },
  {
    path: '/app/questionnaire',
    name: 'questionnaire',
    component: Questionnaire,
    meta: {
      title: 'Onboarding Questionnaire',
      dontSaveToHistory: true,
      isPublicPage: true,
    },
    children: [
      {
        name: 'questionnaire_start',
        path: 'start',
        component: QuestionnaireStart,
        meta: { title: 'Questionnaire Start' },
      },
      {
        name: 'questionnaire_categories',
        path: 'categories',
        component: QuestionnaireCategories,
        meta: { title: 'Questionnaire Categories' },
      },
      {
        name: 'questionnaire_book_one',
        path: 'book_one',
        component: QuestionnaireBook,
        meta: { title: 'Questionnaire Book' },
      },
      {
        name: 'questionnaire_book_two',
        path: 'book_two',
        component: QuestionnaireBook,
        meta: { title: 'Questionnaire Book' },
      },
      {
        name: 'questionnaire_book_three',
        path: 'book_three',
        component: QuestionnaireBook,
        meta: { title: 'Questionnaire Book' },
      },
      {
        name: 'questionnaire_quote_one',
        path: 'quote_one',
        component: QuestionnaireQuote,
        meta: { title: 'Questionnaire Quote' },
      },
      {
        name: 'questionnaire_quote_two',
        path: 'quote_two',
        component: QuestionnaireQuote,
        meta: { title: 'Questionnaire Quote' },
      },
      {
        name: 'questionnaire_quote_three',
        path: 'quote_three',
        component: QuestionnaireQuote,
        meta: { title: 'Questionnaire Quote' },
      },
      {
        name: 'questionnaire_support',
        path: 'support',
        component: QuestionnaireSupport,
        meta: { title: 'Questionnaire Support' },
      },
      {
        name: 'questionnaire_time',
        path: 'time',
        component: QuestionnaireReadingTime,
        meta: { title: 'Questionnaire Time' },
      },
      {
        name: 'questionnaire_when',
        path: 'when',
        component: QuestionnaireReadingWhen,
        meta: { title: 'Questionnaire When' },
      },
      {
        name: 'questionnaire_finish',
        path: 'finish',
        component: QuestionnaireFinish,
        meta: { title: 'Questionnaire Finish' },
      },
    ],
  },
  {
    path: '/app/onboarding',
    name: 'long_onboarding',
    component: LongOnboarding,
    meta: { title: 'Onboarding', dontSaveToHistory: true, isPublicPage: true },
  },
  {
    path: '/app/search',
    name: 'search',
    component: Search,
    meta: { title: 'Search', isRootPage: true },
  },
  {
    path: '/app/books',
    name: 'books',
    component: Books,
    meta: {
      title: 'Books',
      isRootPage: true,
      keepAlive: true,
      componentName: 'Books',
    },
  },
  {
    path: '/app/books/category/:category',
    name: 'bookCategory',
    component: ListPage,
    meta: {
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: '/app/books/collection/:collection',
    name: 'bookCollection',
    component: ListPage,
    meta: {
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: '/app/articles/category/:category',
    name: 'articleCategory',
    component: ListPage,
    meta: {
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: `/app/books/list/:name(${booksListingPageRegex})`,
    name: 'bookSpecialListingPage',
    component: ListPage,
    meta: {
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: `/app/articles/list/:name(${articlesListingPageRegex})`,
    name: 'articleSpecialListingPage',
    component: ListPage,
    meta: {
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: '/app/books/search/:term',
    name: 'bookSearchPage',
    component: ListPage,
    meta: {
      title: 'Search Results',
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: '/app/podcasts/episodes/search/:term',
    name: 'podcast_episodes.search',
    component: ListPage,
    meta: {
      title: 'Search Results',
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: '/app/podcasts/search/:term',
    name: 'podcast.search',
    component: PodcastsSearchPage,
    meta: {
      title: 'Search Results',
      keepAlive: true,
      componentName: 'PodcastsSearchPage',
    },
  },
  {
    path: '/app/articles/search/:term',
    name: 'articleSearchPage',
    component: ListPage,
    meta: {
      title: 'Search Results',
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: '/app/collections',
    name: 'collections',
    component: ListPage,
    meta: {
      title: 'Collections',
      keepAlive: true,
      componentName: 'ListPage',
    },
  },

  {
    path: '/app/library',
    name: 'my_library',
    component: MyLibrary,
    redirect: '/app/library',
    meta: {
      title: 'My Library',
      isRootPage: true,
    },
    children: [
      {
        name: 'my_library.books',
        path: '',
        component: LibraryBooks,
      },
      {
        name: 'my_library.articles',
        path: 'articles',
        component: LibraryArticles,
      },
      {
        name: 'my_library.episodes',
        path: 'podcasts/episodes',
        component: LibraryEpisodes,
      },
      {
        name: 'my_library.custom_lists',
        path: 'lists',
        component: LibraryCustomLists,
      },
    ],
  },

  {
    path: '/app/book/:url_slug',
    // Note: we shouldn't have a name for parent route if we have the
    // default nested route, router produces the following warning:
    // [vue-router] Named Route 'book' has a default child route.
    // When navigating to this named route (:to="{name: 'book'"),
    // the default child route will not be rendered.
    // Remove the name from this route and use the name of the default
    // child route for named links instead.
    //
    // name: 'book',
    component: BookSummaryPage,
    meta: { title: 'Books' },
    children: [
      {
        name: 'book.community',
        // NOTE: community route should be above the book route as
        // we have a conflict here - `book/community` matches the
        // `book/chapter-url-slug` pattern, if book route is higher,
        // it catches the `book/community` URL.
        path: 'community',
        component: BookCommunity,
        meta: { title: 'Discussion' },
      },
      {
        name: 'book.thread',
        path: 'community/:thread',
        component: BookCommunityThread,
        meta: {
          title: 'Discussion Topic',
          dontSaveToHistory: true,
        },
      },
      {
        name: 'book.edit_thread',
        path: 'post',
        component: BookCommunityThreadEdit,
        meta: { title: 'New Post', dontSaveToHistory: true },
      },
      {
        name: 'book.highlights',
        path: 'highlights',
        component: BookHighlights,
        meta: { title: 'Highlights' },
      },
      {
        name: 'book.export',
        path: 'export',
        component: BookExport,
        meta: { title: 'Export', dontSaveToHistory: true },
      },
      {
        name: 'book.page',
        path: ':page_url_slug?',
        component: BookContent,
        meta: { title: 'Book' },
      },
      {
        name: 'book.preview',
        path: 'preview',
        component: DocPreviewPage,
        meta: { title: 'Preview' },
      },
    ],
  },
  {
    path: '/app/podcasts',
    name: 'podcasts',
    component: Podcasts,
    meta: {
      title: 'Podcasts',
      isRootPage: true,
      keepAlive: true,
      componentName: 'Podcasts',
    },
  },
  {
    path: '/app/podcast/:url_slug',
    name: 'podcastPage',
    component: PodcastPage,
    meta: {
      keepAlive: true,
      componentName: 'PodcastPage',
    },
  },
  {
    path: `/app/podcasts/episodes/list/followed`,
    name: 'podcast_episodes.followed',
    component: ListPage,
    meta: {
      title: 'Latest Episodes',
      keepAlive: true,
      componentName: 'ListPage',
    },
  },
  {
    path: `/app/podcasts/episodes/list/:name(${episodesListingPageRegex})`,
    name: 'episodesLibraryListingPage',
    component: EpisodesLibraryListingPage,
    meta: {
      keepAlive: true,
      componentName: 'EpisodesLibraryListingPage',
    },
  },
  {
    path: '/app/podcast/episode/:url_slug',
    component: PodcastEpisodeSummaryPage,
    meta: { title: 'Podcasts' },
    children: [
      {
        name: 'podcast.community',
        // NOTE: community route should be above the book route as
        // we have a conflict here - `book/community` matches the
        // `book/chapter-url-slug` pattern, if book route is higher,
        // it catches the `book/community` URL.
        path: 'community',
        component: BookCommunity,
        meta: { title: 'Discussion' },
      },
      {
        name: 'podcast.thread',
        path: 'community/:thread',
        component: BookCommunityThread,
        meta: {
          title: 'Discussion Topic',
          dontSaveToHistory: true,
        },
      },
      {
        name: 'podcast.edit_thread',
        path: 'post',
        component: BookCommunityThreadEdit,
        meta: { title: 'New Post', dontSaveToHistory: true },
      },
      {
        name: 'podcast.highlights',
        path: 'highlights',
        component: BookHighlights,
        meta: { title: 'Highlights' },
      },
      {
        name: 'podcast.page',
        path: ':page_url_slug?',
        component: BookContent,
        meta: { title: 'Podcast' },
      },
      {
        name: 'podcast.preview',
        path: 'preview',
        component: DocPreviewPage,
        meta: { title: 'Preview' },
      },
    ],
  },
  {
    path: '/app/list/:url_slug',
    name: 'customList',
    component: ListPage,
    meta: {
      parentPage: 'my_library.custom_lists',
      keepAlive: true,
    },
  },
  {
    path: '/app/404',
    name: 'not_found',
    component: Page404,
    meta: { title: 'Page Not Found', dontSaveToHistory: true },
  },
  {
    path: '/404',
    name: 'public_not_found',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/404', next)
    },
    component: NopComponent,
    meta: { isPublicPage: true },
  },
  {
    path: '/app/articles',
    name: 'articles',
    component: Articles,
    meta: {
      title: 'Articles',
      isRootPage: true,
      keepAlive: true,
      componentName: 'Articles',
    },
  },
  {
    path: '/app/article/:url_slug',
    name: 'article',
    component: ArticlePage,
    meta: { title: 'Article' },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ './views/About.vue'),
  //   meta: { title: appTitle + ' - About' },
  // },
  {
    path: '/app/highlights',
    name: 'all_highlights',
    component: HighlightsAll,
    meta: {
      title: 'Highlights',
      isRootPage: true,
    },
  },
  {
    // The `index.html` is set as `start_url` in PWA settings,
    // see public/manifest.json.
    // Here we redirect it to the home page.
    path: '/index.html',
    redirect: '/app',
  },
  {
    path: '/app/billing',
    name: 'billing',
    component: Billing,
    async beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any,
    ): Promise<void> {
      if (
        from.path.includes('/app/questionnaire/finish') ||
        from.path.includes('/app/onboarding')
      ) {
        await billingRedesign15255Experiment()
      }

      next()
    },
    meta: {
      title: 'Billing',
      isRootPage: true,
    },
  },
  {
    path: '/app/help',
    name: 'help',
    component: Help,
    meta: { title: 'Support', isRootPage: true },
  },
  {
    path: '/app/settings',
    name: 'settings',
    component: Settings,
    meta: {
      title: 'Settings',
      isRootPage: true,
    },
  },
  {
    path: '/app/export',
    name: 'export',
    component: Export,
    meta: { title: 'Export Highlights', dontSaveToHistory: true },
  },
  {
    path: '/shared/:book_url_slug/highlights/:highlight_id',
    name: 'public_highlight',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation(to.path, next)
    },
    component: NopComponent,
    meta: { isPublicPage: true },
  },
  {
    path: '/app/highlights/:highlight_id',
    name: 'highlight_page',
    component: HighlightPage,
    meta: { title: 'Highlight ', dontSaveToHistory: true },
  },
  {
    path: '/app/delete/confirm',
    name: 'account_deletion_confirmation',
    component: AccountDeletionConfirmation,
    meta: {
      title: 'Confirm Account Deletion',
      dontSaveToHistory: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: (): Record<string, string> => {
      if (auth.loggedIn()) {
        return { name: 'not_found' }
      }
      return { name: 'public_not_found' }
    },
    meta: {
      dontSaveToHistory: true,
    },
  },
]

export default routes
