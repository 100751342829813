import { backend } from '@/services/backend'
import { CustomListListItem, DocListItem, WaitUI } from './interfaces'
import { resetCustomListsCache } from '@/helpers/cache'
import { Mixpanel } from '@/analytics/mixpanel'
import { Sentry } from '@/services/sentry'
import { DocsView } from './doc'

/**
 * Logic related to the Custom Lists of a given `doc`.
 */
export class DocCustomListsView {
  doc: DocListItem
  private docsView?: DocsView
  private waitHandler: WaitUI
  private updateListsDelay: number | null = null

  lists: CustomListListItem[] = []
  listsState: Record<string, boolean> = {}

  constructor(doc: DocListItem, waitHandler: WaitUI, docsView?: DocsView) {
    this.doc = doc
    this.waitHandler = waitHandler
    this.docsView = docsView
  }

  get isInWantToRead(): boolean {
    return this.doc.custom_lists.some(
      (list) => list.url_slug === 'want-to-read',
    )
  }

  /**
   * Return `true` if the doc is one or more lists, or marked as Favorite.
   */
  get isDocInAnyList(): boolean {
    return this.doc.is_favorite || this.doc.custom_lists.length > 0
  }

  /**
   * Return `true` if the doc is in the given `list`.
   */
  isDocInList(list: CustomListListItem): boolean {
    return this.doc.custom_lists.some((docList) => list.id === docList.id)
  }

  /**
   * Load the user's lists, and marks which ones contain the doc.
   */
  async getLists(): Promise<void> {
    this.waitHandler.end('Error Loading Lists')
    this.waitHandler.start('Loading Lists')

    try {
      this.lists = await backend.getCustomLists()
      this.lists.forEach((list) => {
        this.listsState[list.url_slug] = this.isDocInList(list)
      })
    } catch (error) {
      this.waitHandler.start('Error Loading Lists')

      this.lists = []
      this.listsState = {}

      Sentry.captureException(error)
    }

    this.waitHandler.end('Loading Lists')
  }

  /**
   * Add / Remove the doc from the "Want to Read" list.
   */
  async toggleWantToRead(): Promise<void> {
    const result = this.isInWantToRead
      ? await backend.removeFromWantToRead(this.doc)
      : await backend.addToWantToRead(this.doc)

    Mixpanel.trackWantToRead(result, !this.isInWantToRead)

    await this.updateDoc(result.custom_lists)

    // Reset cache to force fresh data loading.
    // Otherwise, we mark the book as favorite on the books page, for example,
    // then open the home page and the book is not marked as favorite (additional
    // page refresh is needed to see the favorite mark).
    await resetCustomListsCache(this.doc.doc_type)
  }

  /**
   * Adds / removes the doc from the given `list` based on the `updatedValue` value.
   *
   * This function has a 500ms debounce to prevent creating too many requests when the
   * user's toggling many lists at once.
   */
  async toggleList(
    list: CustomListListItem,
    updatedValue: boolean,
    skipDebounce: boolean = false,
  ): Promise<void> {
    this.listsState[list.url_slug] = updatedValue

    if (skipDebounce) {
      await this.updateListsBackend()
      return
    }

    if (this.updateListsDelay) {
      clearTimeout(this.updateListsDelay)
    }

    this.updateListsDelay = setTimeout(() => {
      this.updateListsBackend()
    }, 500)
  }

  /**
   * Sends a backend request to update the doc's lists based on `this.listsState`.
   *
   * @param updateView - Whether or not to update the view. By default, we only update
   *   it when the user closes the popup. This way it won't abruptly remove the doc they
   *   were interacting with.
   */
  async updateListsBackend(updateView: boolean = false): Promise<void> {
    try {
      const result = await backend.updateCustomLists(this.doc, this.listsState)

      Mixpanel.trackAddToCustomList(result)

      await this.updateDoc(result.custom_lists, updateView)

      // Reset cache to force fresh data loading.
      // Otherwise, we mark the book as favorite on the books page, for example,
      // then open the home page and the book is not marked as favorite (additional
      // page refresh is needed to see the favorite mark).
      await resetCustomListsCache(this.doc.doc_type)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  /**
   * Update the doc or docsView.
   *
   * @param newLists - The updated array of custom lists for the doc.
   * @param updateView - Whether or not to update the view. It's useful
   *   to have control over this to avoid the view updating and removing
   *   the doc while the user is still interacting with the lists selection popup.
   */
  async updateDoc(
    newLists: CustomListListItem[],
    updateView: boolean = true,
  ): Promise<void> {
    if (this.docsView && updateView) {
      await this.docsView.updateDocCustomLists(this.doc, newLists)
    } else {
      this.doc.custom_lists = newLists
    }
  }

  /**
   * Clear any remaning timeouts and `lists`
   * and `listsState` data to preserve memory usage.
   */
  async clearState(): Promise<void> {
    if (this.updateListsDelay) {
      clearTimeout(this.updateListsDelay)
      await this.updateListsBackend(true)
    }

    this.lists = []
    this.listsState = {}
  }
}
