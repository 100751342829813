import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72a5c544"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "listing-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListingBlockHeader = _resolveComponent("ListingBlockHeader")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_ListingBlockHeader, {
      to: _ctx.viewAllLink && { path: _ctx.viewAllLink },
      title: _ctx.title,
      viewAllText: _ctx.viewAllText
    }, _createSlots({ _: 2 }, [
      (_ctx.$slots.headerActions)
        ? {
            name: "default",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "headerActions", {}, undefined, true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["to", "title", "viewAllText"]),
    _createElementVNode("div", {
      class: _normalizeClass(["listing-block__inner", _ctx.innerWrapperVariantClass]),
      style: _normalizeStyle(_ctx.numberOfColumnsVariable),
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clickHandler($event)), ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6)
  ]))
}